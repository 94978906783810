import styled from '@emotion/styled';
import { memo, useEffect } from 'react';
import { useSnapshot } from 'valtio';
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2';
import { store } from '~/pages/heineken_template/_private/store';
import { processQuoteToReadable } from '~/utils/processQuoteToReadable';
import { Button, Tooltip } from '@mantine/core';
import { useSymbolDictionaryStore } from '~/modules/SDK/Symbol/useSymbolDictionaryStore';
import { fill_horizontal_cross_center, fill_vertical_main_center, jc, } from '~/modules/AppLayout/FlexGridCss';
import { css } from '@emotion/react';
import { capitalOrderFutures, capitalOrderStock } from './orderUrl';
import { staticStore } from '~/pages/heineken_template/_private/staticStore';
const OrderButton1 = memo(function OrderButton() {
    const charting = useSnapshot(store).charting;
    const dictionary = useSymbolDictionaryStore(s => s.dictionary);
    useEffect(() => {
        signalrStore2.addQuote(charting.symbol);
        return () => {
            signalrStore2.removeQuote(charting.symbol);
        };
    }, [JSON.stringify(charting.symbol)]);
    const value = useSnapshot(signalrStore2.values.quote)[charting.symbol];
    const close = value?.close ?? 0;
    const quoteChanges = processQuoteToReadable(value);
    const changePercent = quoteChanges?.closeChangePercent;
    //處理股票商品
    const orderStock = capitalOrderStock + charting.symbol + '&OrderStatus=';
    return (<styleds1.container>
      <styleds1.body>
        <styleds1.symbolContent>
          <span>{dictionary[charting.symbol]}</span>
          <span>{charting.symbol}</span>
        </styleds1.symbolContent>

        <styleds1.quoteItem quote={changePercent}>
          <span>{close}</span>
          <span>
            {changePercent > 0 ? '+' : ''}
            {changePercent}%
          </span>
        </styleds1.quoteItem>
        <Button css={css `
            width: 100%;
            height: 32px;
          `} variant='gradient' gradient={{ from: 'indigo', to: 'cyan' }} onClick={() => {
            window.open(orderStock, 'WindowOpen', 'toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=500, height=640, top=0, left=0');
        }}>
          網頁下單
        </Button>
      </styleds1.body>
      <styleds1.warringContent>
        <Tooltip multiline width={324} label={warringString()} color='gray'>
          <span>下單警語</span>
        </Tooltip>
      </styleds1.warringContent>
    </styleds1.container>);
});
const styleds1 = {
    container: styled.div `
    width: 100%;
    height: 60px;
    display: grid;
    grid-template-rows: 40px 16px;
    gap: 4px;
  `,
    body: styled.div `
    ${fill_horizontal_cross_center};
    display: grid;
    grid-template-columns: calc(100% - 200px) 100px 100px;
    height: 40px;
    background-color: #25262b;
    border-radius: 4px;
    padding: 4px;
    & > * {
      padding: 0 4px;
    }
  `,
    warringContent: styled.div `
    ${fill_horizontal_cross_center};
    gap: 16px;
    font-size: 12px;
    padding: 0 4px;
  `,
    symbolContent: styled.div `
    ${fill_horizontal_cross_center};
    font-size: 14px;
    overflow: hidden;
    white-space: nowrap;
    gap: 4px;
  `,
    quoteItem: styled.div `
    ${fill_horizontal_cross_center};
    ${jc.spaceBetween};
    color: ${props => (props.quote >= 0 ? '#ff0000' : '#00cc22')};
    font-size: 14px;
  `,
};
const OrderButton2 = memo(function OrderButton2() {
    const charting = useSnapshot(store).charting;
    const dictionary = useSymbolDictionaryStore(s => s.dictionary);
    //處理股票商品
    const orderStock = 'https://capitalh5.capital.com.tw/Login.aspx?source=z7&FuntureID=' +
        charting.symbol +
        '&OrderStatus=';
    return (<styleds2.container>
      <styleds2.symbolContent>
        <span>{charting.symbol}</span>
        <span> {dictionary[charting.symbol]}</span>
      </styleds2.symbolContent>
      <Tooltip multiline width={324} label={warringString2()} closeDelay={1000} color='gray'>
        <Button css={css `
            width: 120px;
            height: 40px;
          `} variant='gradient' gradient={{ from: 'indigo', to: 'cyan' }} onClick={() => {
            window.open(orderStock, 'WindowOpen', 'toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=500, height=640, top=0, left=0');
        }}>
          網頁下單
        </Button>
      </Tooltip>
    </styleds2.container>);
});
const styleds2 = {
    container: styled.div `
    ${fill_horizontal_cross_center};
    height: 48px;
    width: 200px;
    padding: 0 4px;
    border-radius: 4px;
  `,
    symbolContent: styled.div `
    ${fill_vertical_main_center};
    width: 80px;
    display: grid;
    grid-template-rows: 24px 24px;
    font-size: 14px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    & > *:first-child {
      ${fill_horizontal_cross_center};
      width: 100%;
      align-items: end;
    }
    & > *:last-child {
      ${fill_horizontal_cross_center};
      align-items: start;
    }
  `,
};
/** 只支援股期商品下單 其餘商品按鈕將無法作用 */
const OrderButton3 = memo(function OrderButton3() {
    const charting = useSnapshot(store).charting;
    const futuresDisctionary = useSnapshot(staticStore.symbol.stockFuturesMapper);
    const orderSymbol = charting.symbol === 'TSEA' ? '2330' : charting.symbol;
    const stockFuturesSymbol = 'FI' + futuresDisctionary[orderSymbol] ?? 'null';
    const dictionary = useSymbolDictionaryStore(s => s.dictionary);
    //帶入商品的下單網址
    const orderStock = capitalOrderFutures + stockFuturesSymbol;
    const disabled = stockFuturesSymbol.includes('undefined');
    return (<styleds2.container>
      <Tooltip multiline width={324} label={warringString2()} closeDelay={1000} color='gray'>
        <Button css={css `
            width: 136px;
            height: 40px;
            font-size: 13px;
            padding-right: 0;
            padding-left: 0;
          `} variant='gradient' gradient={{ from: 'indigo', to: 'cyan' }} disabled={disabled} onClick={() => {
            window.open(orderStock, 'WindowOpen', 'toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=500, height=640, top=0, left=0');
        }}>
          {disabled ? '' : dictionary[orderSymbol] + '期'} 網頁下單
        </Button>
      </Tooltip>
    </styleds2.container>);
});
export default {
    Display1: OrderButton1,
    Display2: OrderButton2,
    Display3: OrderButton3,
};
const warring = `
交易人投資前應了解商品風險，審慎評估投資能力與自身風險承受能力，並自負盈虧。
2.下單系統及輔助工具僅供參考，投資人仍需自行判斷，任何系統參數須由投資人自行設定。
3.期貨及選擇權交易具低保證金之高度財務槓桿特性，在可能產生極大利潤的同時也可能產生極大的損失，投資人於開戶前應審慎考慮本身的財務能力及經濟狀況。
4.過去的績效並不代表未來獲利，投資人仍須依據本身狀況做好審慎評估。
5.本公司所提供之即時報價資訊，不代表勸誘投資人進行期貨交易，且不保證此資料之正確性及完整性。
6.使用本公司的電子下單系統交易委託買賣，可能面臨斷線、斷電、網路壅塞等阻礙，致使委託買賣無法傳送接收或時間延遲。
`;
const warringString = () => {
    return (<div>
      {warring.split('\n').map((line, i) => (<p key={i}>{line}</p>))}
    </div>);
};
const warringString2 = () => {
    return (<div css={css `
        width: 100%;
      `}>
      {warring.split('\n').map((line, i) => (<p key={i}>{line}</p>))}
      <a href={'https://'} target='_blank' rel='noreferrer' css={css `
          width: 100%;
          color: #2288ff;
        `}>
        點擊進入群益線上開戶
      </a>
    </div>);
};
