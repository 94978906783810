/* eslint-disable no-var */
import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
export const sg4 = createIndicator({
    id: 'stocksg4',
    displayName: '出手123',
    filterOnTheme: 'dark',
    constructorScope: {
        init(context, inputCallback) {
            localStorage.setItem('signalInitializedTimestamp', new Date().getTime().toString());
        },
        main(context, inputCallback) {
            const donchian = (period) => {
                const lowVar = context.new_var(this.ohlc.low);
                const highVar = context.new_var(this.ohlc.high);
                const value = this.PineJS.Std.avg(this.PineJS.Std.lowest(lowVar, period, context), this.PineJS.Std.highest(highVar, period, context));
                return value;
            };
            const f_1 = () => {
                const v1 = donchian(7);
                const v2 = donchian(29);
                const v3 = donchian(47);
                return [v1, v2, v3];
            };
            const i = f_1();
            const donchian_1 = this._context.new_var(i[0]);
            const donchian_2 = this._context.new_var(i[1]);
            const donchian_3 = this._context.new_var(i[2]);
            const donchian_4 = this._context.new_var((donchian_1 + donchian_2) / 2);
            donchian_3.get(15);
            donchian_4.get(15);
            const channel_mid = donchian_2.get(0);
            const channel_low = Math.min(donchian_3.get(13), donchian_4.get(13));
            const channel_high = Math.max(donchian_3.get(13), donchian_4.get(13));
            let pv0 = NaN;
            let pv1 = NaN;
            const close = this.PineJS.Std.close(this._context);
            const interval = context.symbol.period;
            var bState = this._context.new_var();
            bState.get(2);
            //部位進場-------------------------------------------------------------------
            if (close > channel_high && close < channel_mid && close > channel_mid * 0.95) {
                bState.set(1);
            }
            else if (close < channel_low && close > channel_mid && close < channel_mid * 1.05) {
                bState.set(-1);
            }
            else {
                bState.set(0);
            }
            //部位進場與成立訊號-------------------------------------------------------------------
            if (bState.get(0) === 1 && bState.get(1) !== 1) {
                pv0 = 1;
                pv1 = NaN;
            }
            if (bState.get(0) === -1 && bState.get(1) !== -1) {
                pv0 = NaN;
                pv1 = 1;
            }
            return [pv0, pv1];
        },
    },
    metainfo: {
        _metainfoVersion: 27,
        isTVScript: false,
        isTVScriptStub: false,
        is_hidden_study: false,
        isCustomIndicator: true,
        visible: true,
        defaults: {
            styles: {
                plot_0: {
                    color: '#fff554',
                    textColor: '#ff5554',
                    transparency: 0,
                    visible: true,
                },
                plot_1: {
                    color: '#2ffafd',
                    textColor: '#2f7afd',
                    transparency: 0,
                    visible: true,
                },
            },
            inputs: {},
        },
        plots: [
            {
                id: 'plot_0',
                type: 'shapes',
            },
            {
                id: 'plot_1',
                type: 'shapes',
            },
        ],
        styles: {
            plot_0: {
                isHidden: false,
                location: 'BelowBar', // Top, Bottom
                plottype: 'shape_triangle_up',
                size: 'small',
                text: '',
                title: 'Shapes',
            },
            plot_1: {
                isHidden: false,
                location: 'AboveBar', // Top, Bottom
                plottype: 'shape_triangle_down',
                size: 'small',
                text: '',
                title: 'Shapes',
            },
        },
        is_price_study: true,
        inputs: [],
        scriptIdPart: '',
    },
});
