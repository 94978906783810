import styled from '@emotion/styled';
import { memo, useCallback } from 'react';
import { useSnapshot } from 'valtio';
import { fill_horizontal_all_center, fill_vertical_all_center, } from '~/modules/AppLayout/FlexGridCss';
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2';
import { useSymbolDictionaryStore } from '~/modules/SDK/Symbol/useSymbolDictionaryStore';
import { store } from '~/pages/heineken_template/_private/store';
import { processQuoteToReadable } from '~/utils/processQuoteToReadable';
import { stockDecimal } from '../stockDecimal';
import { staticStore } from '~/pages/heineken_template/_private/staticStore';
import { useSortSignalrDataStore, useSortSignalrDataStore2, useSortSignalrDataStore3, } from '~/modules/symbolQuote/simple/useSortStore';
import { SortArrow } from '../SortArrow';
import { useThemeStore } from '~/components/theme/useThemeStore';
import { css } from '@emotion/react';
import { baseQuoteStyled, baseSelectedStyled } from '../baseQuoteStyled';
const DefaultHeader = memo(function DefaultHeader(props) {
    const theme = useThemeStore(t => t.theme);
    const sortGroup = props.sortGroup ?? 1;
    /** 排序元件的組別 */
    const sortStore = () => {
        if (sortGroup === 2) {
            return useSortSignalrDataStore2;
        }
        else if (sortGroup === 3) {
            return useSortSignalrDataStore3;
        }
        else {
            return useSortSignalrDataStore;
        }
    };
    const sortState = useSnapshot(sortStore());
    const toggleOrderKey = sortState.orderKey === 'desc' ? 'asc' : 'desc';
    const handleColumnSelected = useCallback((sortKey) => {
        sortStore().sortKey = sortKey;
        sortStore().orderKey = toggleOrderKey;
    }, [toggleOrderKey]);
    return (<classes.root css={baseStyled}>
      <classes.header.container className={theme}>
        <classes.header.item>-</classes.header.item>
        <classes.header.item onClick={() => handleColumnSelected('symbol')}>
          商品名稱
          <SortArrow sortKey='symbol' sotre={sortStore()}/>
        </classes.header.item>
        <classes.header.item onClick={() => handleColumnSelected('close')}>
          成交
          <SortArrow sortKey='close' sotre={sortStore()}/>
        </classes.header.item>
        <classes.header.item onClick={() => handleColumnSelected('change')}>
          漲跌
          <SortArrow sortKey='change' sotre={sortStore()}/>
        </classes.header.item>
        <classes.header.item onClick={() => handleColumnSelected('changePrecent')}>
          漲跌幅
          <SortArrow sortKey='changePrecent' sotre={sortStore()}/>
        </classes.header.item>
        <classes.header.item onClick={() => handleColumnSelected('volume')}>
          成交量
          <SortArrow sortKey='volume' sotre={sortStore()}/>
        </classes.header.item>
      </classes.header.container>
    </classes.root>);
});
const DefaultBody = memo(function DefaultBody(props) {
    const theme = useThemeStore(t => t.theme);
    const quote = props.quote;
    /** 商品代號 */
    const symbol = quote?.symbol;
    const dictionary = useSymbolDictionaryStore(s => s.dictionary);
    const value = useSnapshot(signalrStore2.values.quote)[quote?.symbol];
    /** 商品名稱 */
    const symbolName = dictionary[symbol];
    /** 成交 */
    const close = value?.close ?? 0;
    /** 成交量 */
    const originalVolume = value?.volume ?? 0;
    /** 成交量轉 */
    const volume = originalVolume > 1e6 ? originalVolume / 1e6 : originalVolume;
    /** 成交量單位 */
    const volumeUnit = originalVolume > 1e6 ? '億' : '';
    /** 漲跌函式 */
    const quoteChange = processQuoteToReadable(value);
    /** 漲跌點 */
    const quoteChanges = quoteChange.closeChange;
    /** 漲跌百分比 */
    const quoteChangesPercent = quoteChange.closeChangePercent;
    /** 是否是漲跌停價格 */
    const limitPrice = close === value?.limitDownPrice || close === value?.limitUpPrice;
    /** 漲跌符號 */
    const changeArrow = quoteChangesPercent > 0 ? '+' : '';
    /** 是否在選擇中 */
    const charting = useSnapshot(store).charting;
    const selected = charting.symbol === symbol;
    const staticState = useSnapshot(staticStore);
    const futuresDisctionary = staticState.symbol.stockFuturesMapper;
    const stockBondDisctionary = staticState.symbol.stockBondsMapper;
    return (<classes.root css={baseStyled}>
        <classes.body.container css={baseSelectedStyled(selected, selecteConfig)} className={theme} onClick={() => {
            store.charting.changeSymbol(symbol);
            props.onClick?.();
        }}>
          <div css={fill_vertical_all_center}>
            {futuresDisctionary?.[symbol] && (<classes.body.dictionary onClick={event => {
                event.stopPropagation();
                store.charting.changeSymbol(futuresDisctionary[symbol] + '-1');
            }} css={css `
                  background-color: #52a889;
                  &:hover {
                    transform: scale(1.06);
                    transition: 0.3s;
                  }
                `}>
                期
              </classes.body.dictionary>)}
            {stockBondDisctionary?.[symbol] && (<classes.body.dictionary onClick={event => {
                event.stopPropagation();
                store.charting.changeSymbol(stockBondDisctionary[symbol]?.[0] ?? symbol);
            }} css={css `
                  background-color: #4f89da;
                  &:hover {
                    transform: scale(1.06);
                    transition: 0.3s;
                  }
                `}>
                債
              </classes.body.dictionary>)}
          </div>
          <classes.body.item>
            <classes.body.name>
              <span>{symbolName}</span>
              <span>{symbol}</span>
            </classes.body.name>
          </classes.body.item>
          <classes.body.item>
            <classes.body.quote className={theme} css={baseQuoteStyled(limitPrice, quoteChangesPercent, classesQuoteConfig)}>
              {close.toFixed(stockDecimal(close))}
            </classes.body.quote>
          </classes.body.item>
          <classes.body.item>
            <classes.body.quote className={theme} css={baseQuoteStyled(false, quoteChangesPercent, classesQuoteConfig)}>
              {changeArrow}
              {quoteChanges.toFixed(stockDecimal(close))}
            </classes.body.quote>
          </classes.body.item>
          <classes.body.item>
            <classes.body.quote className={theme} css={baseQuoteStyled(false, quoteChangesPercent, classesQuoteConfig)}>
              {changeArrow}
              {quoteChangesPercent.toFixed(2)}%
            </classes.body.quote>
          </classes.body.item>

          <classes.body.item>
            <classes.body.value className={theme}>
              {volume}
              {volumeUnit}
            </classes.body.value>
          </classes.body.item>
        </classes.body.container>
      </classes.root>);
});
const classes = {
    root: styled.div `
    height: 36px;
    width: 100%;
  `,
    header: {
        container: styled.div `
      display: grid;
      grid-template-columns: 8% 20% 18% 18% 18% 18%;
      height: 36px;
      width: 100%;
      border-radius: 4px;
    `,
        item: styled.div `
      ${fill_horizontal_all_center};
      font-size: 13px;
    `,
    },
    body: {
        container: styled.div `
      display: grid;
      grid-template-columns: 8% 20% 18% 18% 18% 18%;
      height: 36px;
      width: 100%;
      border-radius: 4px;
      padding: 0 2px;
      font-weight: 600;
      cursor: pointer;
      user-select: none;
      flex-shrink: 0;
    `,
        item: styled.div `
      ${fill_horizontal_all_center};
      justify-content: end;
      font-size: 13px;
    `,
        name: styled.div `
      ${fill_vertical_all_center};
      align-items: start;
      font-size: 12px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      &:hover {
        overflow: visible;
      }
      & > * {
        line-height: 16px;
      }
    `,
        quote: styled.div `
      border-radius: 2px;
      padding: 2px;
    `,
        value: styled.div ``,
        dictionary: styled.div `
      ${fill_vertical_all_center};
      width: 16px;
      height: 16px;
      font-size: 10px;
      font-weight: 600;
      transform: scale(0.84);
      padding: 4px;
      border-radius: 2px;
    `,
    },
};
const baseStyled = css `
  ${classes.root} {
  }
  ${classes.header.container} {
    &.dark {
      background-color: #252525;
    }
    &.light {
      background-color: #dedede;
    }
    &:hover {
      &.dark {
        background-color: #252525aa;
      }
      &.light {
        background-color: #dededeaa;
      }
    }
  }
  ${classes.body.container} {
    &.dark {
      background-color: #151515;
    }
    &.light {
      background-color: #efefef;
    }
  }
  ${classes.body.value} {
    &.dark {
      color: #ffff00;
    }
    &.light {
      color: #0099cc;
    }
  }
`;
const selecteConfig = {
    selectedStyled: css `
    &.dark {
      border: 1px solid #cacaca;
    }
    &.light {
      border: 1px solid #787878;
    }
  `,
    noSelectedStyled: css `
    &.dark {
      border: 1px solid transparent;
    }
    &.light {
      border: 1px solid transparent;
    }
  `,
};
const classesQuoteConfig = {
    riseLimitStyled: css `
  &.dark {
    background-color:'#ff0000',
    color:#ffffff
  }
  &.light {
    background-color:'#ff0000',
    color:#ffffff
  }
`,
    fallLimitStyled: css `
  &.dark {
    background-color: #00cc00;
    color: #ffffff;
  }
  &.light {
    background-color: #00cc00;
    color:;#ffffff;
  }
`,
    riseStyled: css `
    &.dark {
      color: '#ff0000';
    }
    &.light {
      color: '#ff0000';
    }
  `,
    fallStyled: css `
    &.dark {
      color: #00cc00;
    }
    &.light {
      color: #00cc00;
    }
  `,
    flatStyled: css `
    &.dark {
      color: #ffffff;
    }
    &.light {
      color: #ffffff;
    }
  `,
};
export default {
    body: DefaultBody,
    header: DefaultHeader,
    classesQuoteConfig,
    classes,
};
