import { proxy } from 'valtio';
import { FuiUserAvatar } from '~/pages/heineken_template/components/FuiUserAvatar';
import dayAPI from '~/utils/dayAPI';
export const edwin_store = proxy({
    //sidepan1 模式
    mode: 'screener',
    userAvatar: new FuiUserAvatar(),
    //選股模式 強勢/弱勢
    screenType: 'strong',
    //
    displayType: 'card',
    //產業模式 預設熱門|相關產業
    industryType: 'default',
    //選股類別
    screenerType: 'realTime',
    //排行類別
    rankingType: 'realTime',
    //歷史選股日期
    screenerDate: '',
    //歷史排行日期
    rankingDate: '',
    //選股標記
    screenerMarkDate: '',
    //法人類別
    big2Type: 'all',
    //法人動作
    big2ActiveType: 'buy',
    //法人日期 若最新的日期沒資料,會塞入前一天的日期
    big3Date: dayAPI(),
    //這邊是在法人部分merge data後,將結果傳入
    big2Symbol: [''],
    //顯示有股期
    filterStockFutures: false,
    //顯示有可轉債
    filterStockBonds: false,
    //法人買超金額範圍
    big3AmountRange: [1e8, 1e9],
});
