import { css } from '@emotion/react';
import { memo, useEffect } from 'react';
import { proxy, useSnapshot } from 'valtio';
import { fill_horizontal_all_center, fill_vertical_all_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import { useExtremeVolumeResource, usePercentRangeResource, } from '~/modules/screener/containers/useStockScreenerResource';
import IntradayTrendChart from '~/modules/trendChart';
import { store } from '../heineken_template/_private/store';
import TitleSymbolQuote from '../../modules/investment-consultant/symbolList/body/SymbolQuoteTitle';
import { styleds } from './styleds';
import { staticStore } from '../heineken_template/_private/staticStore';
import dayAPI from '~/utils/dayAPI';
import { useVirtualTransaction } from '~/modules/virtualExchange/useVirtualExchangeResource';
import FuiButton from '../heineken_template/components/FuiButton';
import { more888Stock_strategiesGroup } from './more888Stock_initStrategies';
import QuickStockOrderBox from '~/modules/investment-consultant/orderBox/QuickStockOrderBox';
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2';
import { signalrHooks2 } from '~/modules/SDK/Signalr/signalrHooks2';
import { useSignalr2QuoteSort } from '~/modules/symbolPlatform/useSignalr2QuoteSort';
import { DefaultQuoteList } from '~/modules/new-version-counsultant/Quote/defaultQuote/DefaultList';
export const dailyRankStateEnum = {
    new_high: '創高',
    new_low: '創低',
    near_high: '區間高',
    near_low: '區間低',
};
export const daysEnum = {
    3: '3天',
    5: '5天',
    10: '10天',
    20: '20天',
    60: '60天',
    120: '120天',
};
const more888Stock_store = proxy({
    /** 篩選的天數 `number` */
    priceDays: 20,
    /** 篩選的天數 `number` */
    volumeDays: 3,
    /** 價格篩選中的類型 `創新高` `創新低` */
    priceState: 'new_high',
    /** 爆量篩選中的倍數 `number` */
    threshold: 1.25,
    symbolType: 'orderPre',
});
export const More888Stock_SidePane1 = memo(function More888Stock_SidePane1() {
    const state = useSnapshot(more888Stock_store);
    const date = dayAPI(useSnapshot(staticStore).tradedDate.intraday).format('YYYY/MM/DD');
    const dailyRankLongData = usePercentRangeResource({
        date: date,
        days: state.priceDays,
        status: state.priceState,
        volume_gte: 5000,
        volume_lte: 0,
    });
    const dailyRankShortData = usePercentRangeResource({
        date: date,
        days: 20,
        status: 'new_low',
        volume_gte: 500,
        volume_lte: 0,
    });
    const extremeVolumeData = useExtremeVolumeResource({
        date: date,
        days: state.volumeDays,
        volume_gte: 5000,
        volume_lte: 0,
        threshold: 1.25,
    });
    const orderLongEntry = useVirtualTransaction('big3_stock', { beginDatetime: date })
        ?.filter(s => s.bs === 'B' && s.positionType === 'O')
        .map(s => s.symbol);
    const orderShortEntry = useVirtualTransaction('big3_stock', { beginDatetime: date })
        ?.filter(s => s.bs === 'S' && s.positionType === 'O')
        .map(s => s.symbol);
    const dailyRankLongSymbol = dailyRankLongData.data?.map(s => s.symbol);
    const dailyRankShortSymbol = dailyRankShortData.data?.map(s => s.symbol);
    const extremeVolumeSymbol = extremeVolumeData.data?.map(s => s.symbol);
    const dayTradeLong = dailyRankLongSymbol?.concat(extremeVolumeSymbol ?? []);
    const dayTradeShort = dailyRankShortSymbol; //?.filter(s => extremeVolumeSymbol?.includes(s))
    const charting = useSnapshot(store.charting);
    const symbolNumber = charting.symbol;
    //將陣列中重複的商品只保留一個
    const resultLong = dayTradeLong?.filter((s, index, self) => self.indexOf(s) === index);
    const resultShort = dayTradeShort?.filter((s, index, self) => self.indexOf(s) === index);
    const longData = state.symbolType === 'orderPre' ? orderLongEntry : resultLong;
    const shortData = state.symbolType === 'orderPre' ? orderShortEntry : resultShort;
    const indicators = state.symbolType === 'orderPre'
        ? more888Stock_strategiesGroup.main[0]
        : more888Stock_strategiesGroup.main[1];
    useEffect(() => {
        signalrStore2.addQuote(longData);
        return () => {
            signalrStore2.removeQuote(longData);
        };
    }, [JSON.stringify(longData)]);
    useEffect(() => {
        signalrStore2.addQuote(shortData);
        return () => {
            signalrStore2.removeQuote(shortData);
        };
    }, [JSON.stringify(shortData)]);
    const longDataValue = signalrHooks2.useQuotes(useSignalr2QuoteSort(longData ?? [''], 1));
    const shortDataValue = signalrHooks2.useQuotes(useSignalr2QuoteSort(shortData ?? [''], 2));
    return (<styleds.SideBar>
      <TitleSymbolQuote.Default symbol={symbolNumber}/>
      <div css={css `
          width: 100%;
          height: 140px;
          padding: 4px;
        `}>
        <IntradayTrendChart symbol={symbolNumber} ticksSize={14} ticksHeight={20} priceTicksMargin={2.5} priceTicksSize={14}/>
      </div>

      <div css={css `
          ${fill_horizontal_all_center};
          width: 100%;
          height: 28px;
          gap: 8px;
        `}>
        <FuiButton.Display onClick={() => (more888Stock_store.symbolType = 'orderPre')} active={state.symbolType === 'orderPre'}>
          剛出訊波段股票
        </FuiButton.Display>
        <FuiButton.Display onClick={() => (more888Stock_store.symbolType = 'screener')} active={state.symbolType === 'screener'}>
          最新當沖股票
        </FuiButton.Display>
      </div>
      <div css={css `
          width: 100%;
          height: calc(100% - 324px);
          background-color: #20232c;
          border-radius: 5px;
        `}>
        <div css={css `
            ${fill_vertical_all_center};
            background-color: #cc1100;
            width: 100%;
            height: 28px;
            border-radius: 5px;
          `}>
          多方標的
        </div>
        <div css={css `
            width: 100%;
            height: calc(50% - 30px);
          `}>
          <DefaultQuoteList sortGroup={1} qoutes={longDataValue} symbols={longData ?? ['']} onClick={() => {
            store.charting.setStrategy(indicators);
            store.charting.updateFromStrategyState();
        }}/>
        </div>
        <div css={css `
            ${fill_vertical_all_center};
            background-color: #11cc00;
            width: 100%;
            height: 28px;
            border-radius: 5px;
          `}>
          空方標的
        </div>
        <div css={css `
            width: 100%;
            height: calc(50% - 30px);
          `}>
          <DefaultQuoteList sortGroup={2} qoutes={shortDataValue} symbols={shortData ?? ['']} onClick={() => {
            store.charting.setStrategy(indicators);
            store.charting.updateFromStrategyState();
        }}/>
        </div>
      </div>
      <div css={css `
          ${fill_vertical_cross_center};
          justify-content: end;
          height: 96px;
          padding: 4px;
        `}>
        <QuickStockOrderBox.Display />
      </div>
    </styleds.SideBar>);
});
