import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
import dayAPI from '~/utils/dayAPI';
export const freeman_diff = createIndicator({
    id: 'freeman-diff',
    displayName: '多空差額',
    constructorScope: {
        init(context, inputcallback) {
            const ticker = this.PineJS.Std.ticker(context)
                .toString()
                .replace(/[^\w-]/g, '');
            const symbol = ticker + '#bs_indicator_acc';
            context.new_sym(symbol, this.PineJS.Std.period(this._context));
        },
        main(context, inputCallback) {
            context.select_sym(1);
            const acc = this.PineJS.Std.high(this._context);
            const bar = this._context.new_var(acc);
            const newValue = this._context.new_var();
            //buy_volume
            const buy_volume = this.PineJS.Std.close(this._context);
            const sell_volume = this.PineJS.Std.volume(this._context);
            if (buy_volume > sell_volume) {
                newValue.set(newValue.get(0) + buy_volume);
            }
            else if (buy_volume < sell_volume) {
                newValue.set(newValue.get(0) - sell_volume);
            }
            bar.get(5);
            const itime = this.PineJS.Std.time(this._context);
            const hours = dayAPI(itime).hour();
            const minutes = dayAPI(itime).minute();
            const openTime = hours === 8 && minutes === 45;
            const diff = openTime ? bar.get(0) : bar.get(0) - bar.get(1);
            const colorIndex = diff >= 0 ? 0 : 1;
            return [buy_volume - sell_volume, diff, 1000, 2000, -1000, -2000, colorIndex];
        },
    },
    metainfo: {
        precision: 2,
        plots: [
            {
                id: 'acc',
                type: 'line',
            },
            {
                id: 'bar',
                type: 'line',
            },
            {
                id: 'line_p1000',
                type: 'line',
            },
            {
                id: 'line_p2000',
                type: 'line',
            },
            {
                id: 'line_n1000',
                type: 'line',
            },
            {
                id: 'line_n2000',
                type: 'line',
            },
            {
                id: 'barPalette',
                type: 'colorer',
                target: 'bar',
                palette: 'barPalette',
            },
        ],
        palettes: {
            barPalette: {
                colors: {
                    0: {
                        name: '能量',
                    },
                    1: {
                        name: '能量',
                    },
                },
            },
        },
        defaults: {
            styles: {
                acc: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 2,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: '#ff8800',
                },
                bar: {
                    linestyle: 0,
                    linewidth: 5,
                    plottype: 5,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: '#ff8800',
                },
                line_p1000: {
                    linestyle: 1,
                    linewidth: 1,
                    plottype: 2,
                    trackPrice: !1,
                    transparency: 66,
                    visible: !0,
                    color: '#aa2222',
                },
                line_p2000: {
                    linestyle: 1,
                    linewidth: 1,
                    plottype: 2,
                    trackPrice: !1,
                    transparency: 66,
                    visible: !0,
                    color: '#aa2222',
                },
                line_n1000: {
                    linestyle: 1,
                    linewidth: 1,
                    plottype: 2,
                    trackPrice: !1,
                    transparency: 66,
                    visible: !0,
                    color: '#22aa22',
                },
                line_n2000: {
                    linestyle: 1,
                    linewidth: 1,
                    plottype: 2,
                    trackPrice: !1,
                    transparency: 66,
                    visible: !0,
                    color: '#22aa22',
                },
            },
            palettes: {
                barPalette: {
                    colors: {
                        0: {
                            color: '#cc2222',
                            width: 0,
                            style: 0,
                        },
                        1: {
                            color: '#22cc22',
                            width: 0,
                            style: 0,
                        },
                    },
                },
            },
        },
        styles: {
            acc: {
                title: '成本線',
                histogramBase: 0,
                joinPoints: !1,
            },
            bar: {
                title: '差額',
                histogramBase: 0,
                joinPoints: !1,
            },
            line_p1000: {
                title: '1000',
                histogramBase: 0,
                joinPoints: !1,
            },
            line_p2000: {
                title: '2000',
                histogramBase: 0,
                joinPoints: !1,
            },
            line_n1000: {
                title: '-1000',
                histogramBase: 0,
                joinPoints: !1,
            },
            line_n2000: {
                title: '-2000',
                histogramBase: 0,
                joinPoints: !1,
            },
        },
        is_price_study: !1,
        inputs: [],
        scriptIdPart: '',
    },
});
