import styled from '@emotion/styled';
import { memo, useMemo } from 'react';
import { proxy, useSnapshot } from 'valtio';
import { fill_horizontal_all_center, fill_vertical_cross_center, jc, } from '~/modules/AppLayout/FlexGridCss';
import { Button, Notification, Tabs } from '@mantine/core';
import { css } from '@emotion/react';
import { useStockPriceChangeDistributionState } from '../futuresai/stock-price-change-distribution/components/useStockPriceChangeDistributionState';
import { TbSwitchVertical, TbViewfinder } from 'react-icons/tb';
import { HiFire } from 'react-icons/hi';
import { BsGrid1X2 } from 'react-icons/bs';
import { StockGroup } from './component/StockGroup';
import { StockPopular } from './component/StockPopular';
import { StockPick } from './component/StockPick';
import { wu5868_strategiesDayTradeGroup } from './wu5868_initStrategies';
import { store } from '../heineken_template/_private/store';
import { StockSignal } from './component/StockSignal';
import { StockFirebaseLong } from './component/StockFirebaseLong';
import { FaHandHoldingUsd } from 'react-icons/fa';
import { StockHolding } from './component/StockHolding';
import { AiOutlineFall, AiOutlineRise } from 'react-icons/ai';
import { StockFirebaseShort } from './component/StockFirebaseShort';
import { useNotification } from './component/useNotification';
import { staticStore } from '../heineken_template/_private/staticStore';
import { useVirtualTransaction } from '~/modules/virtualExchange/useVirtualExchangeResource';
export const wu5868_store = proxy({
    tradeDate: '',
    signalType: 'daily',
    tab: 'screener',
});
export const Wu5868_SidePane2 = memo(function Wu5868_SidePane2(props) {
    const charting = useSnapshot(store.charting);
    const lastTradeDate = useSnapshot(staticStore).tradedDate.day0;
    //分鐘進出場訊號
    const minuteStrategySymbol = useVirtualTransaction('wu5868_minute', {
        beginDatetime: lastTradeDate.add(0, 'day').format('YYYY-MM-DD'),
    }, { refreshInterval: 20000 });
    const minuteEntrySymbol = useMemo(() => {
        return minuteStrategySymbol
            ?.filter(datum => datum.bs === 'B' && datum.positionType === 'O')
            .map(datum => datum.symbol);
    }, [minuteStrategySymbol]);
    const { state, acts } = useNotification(minuteEntrySymbol);
    const storeState = useSnapshot(wu5868_store);
    return (<styleds.container>
      <styleds.indicatorsGroup>
        {wu5868_strategiesDayTradeGroup.main.map(config => {
            const isActive = charting.strategySelected.displayName === config.displayName;
            return (<Button css={css `
                width: 100%;
              `} compact key={config.displayName} variant='gradient' gradient={isActive
                    ? { from: 'red', to: 'pink', deg: 119 }
                    : { from: 'gray', to: 'gray', deg: 212 }} onClick={event => {
                    store.charting.setStrategy(config);
                    store.charting.updateFromStrategyState();
                }}>
              {config.displayName}
            </Button>);
        })}
      </styleds.indicatorsGroup>
      <Tabs defaultValue='screener' css={css `
          ${fill_vertical_cross_center};
          height: calc(100% - 36px);
          gap: 4px;
        `}>
        <Tabs.List css={css `
            width: 100%;
            height: 80px;
            box-shadow: 1px 0px 4px 1px #cacaca;
            border-radius: 4px;
            padding: 4px;
          `}>
          <Tabs.Tab value='screener' icon={<TbViewfinder size='0.8rem'/>} onClick={() => (wu5868_store.tab = 'screener')}>
            選股
          </Tabs.Tab>
          <Tabs.Tab value='signal' icon={<TbSwitchVertical size='0.8rem'/>} onClick={() => (wu5868_store.tab = 'signal')}>
            訊號
          </Tabs.Tab>
          <Tabs.Tab value='popular' icon={<HiFire size='0.8rem'/>} onClick={() => (wu5868_store.tab = 'popular')}>
            熱門股
          </Tabs.Tab>
          <Tabs.Tab value='group' icon={<BsGrid1X2 size='0.8rem'/>} onClick={() => (wu5868_store.tab = 'group')}>
            族群
          </Tabs.Tab>
          <Tabs.Tab value='master-long' icon={<AiOutlineRise size='0.8rem'/>} onClick={() => (wu5868_store.tab = 'master-long')}>
            大師(多)
          </Tabs.Tab>
          <Tabs.Tab value='master-short' icon={<AiOutlineFall size='0.8rem'/>} onClick={() => (wu5868_store.tab = 'master-short')}>
            大師(空)
          </Tabs.Tab>
          <Tabs.Tab value='holding' icon={<FaHandHoldingUsd size='0.8rem'/>} onClick={() => (wu5868_store.tab = 'holding')}>
            持有
          </Tabs.Tab>
        </Tabs.List>

        {storeState.tab === 'screener' && (<Tabs.Panel value='screener' css={css `
              width: 100%;
              height: calc(100% - 86px);
            `}>
            <StockPick />
          </Tabs.Panel>)}
        {storeState.tab === 'signal' && (<Tabs.Panel value='signal' css={css `
              width: 100%;
              height: calc(100% - 86px);
            `}>
            <StockSignal />
          </Tabs.Panel>)}

        {storeState.tab === 'popular' && (<Tabs.Panel value='popular' css={css `
              width: 100%;
              height: calc(100% - 86px);
            `}>
            <StockPopular />
          </Tabs.Panel>)}

        {storeState.tab === 'group' && (<Tabs.Panel value='group' css={css `
              height: calc(100% - 86px);
            `}>
            <useStockPriceChangeDistributionState.Provider>
              <StockGroup />
            </useStockPriceChangeDistributionState.Provider>
          </Tabs.Panel>)}

        {storeState.tab === 'master-long' && (<Tabs.Panel value='master-long' css={css `
              width: 100%;
              height: calc(100% - 86px);
            `}>
            <StockFirebaseLong />
          </Tabs.Panel>)}

        {storeState.tab === 'master-short' && (<Tabs.Panel value='master-short' css={css `
              width: 100%;
              height: calc(100% - 86px);
            `}>
            <StockFirebaseShort />
          </Tabs.Panel>)}

        {storeState.tab === 'holding' && (<Tabs.Panel value='holding' css={css `
              width: 100%;
              height: calc(100% - 86px);
            `}>
            <StockHolding />
          </Tabs.Panel>)}
      </Tabs>

      {state.enbleNotify === true && (<Notification css={css `
            position: fixed;
            width: 300px;
            border: 1px solid #454545;
            right: 4px;
            bottom: 4px;
            z-index: 300;
          `} title='商品部位變動通知' withBorder={true} onClose={() => acts.setEnbleNotify(false)}>
          <div css={css `
              ${fill_vertical_cross_center};
              justify-content: start;
              align-items: start;
            `}>
            <span>05訊號部位通知</span>
            <span>異動商品代碼: {state.changeSymbols.join(' ')}</span>
          </div>
        </Notification>)}
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    position: relative;
    ${fill_vertical_cross_center};
    background-color: #fafafa;
    padding: 4px;
    border-right: 1px solid #aaaaaa;
    gap: 4px;
  `,
    indicatorsGroup: styled.div `
    ${fill_horizontal_all_center};
    ${jc.spaceEvenly};
    height: 30px;
    gap: 8px;
  `,
    indicator: styled.div `
    border: 1px solid ${props => (props.isActive ? '#252525' : 'transparent')};
    padding: 0 4px;
    border-radius: 2px;
  `,
};
