import styled from '@emotion/styled';
import { scrollbar2Css } from '~/css/scrollbarCss';
import { fill_horizontal_all_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
export const styleds = {
    SideBar: styled.div `
    ${fill_vertical_cross_center};
    background-color: #191b23;
    padding: 8px 4px;
    gap: 8px;
    ${scrollbar2Css};
  `,
    StrategyGroup: styled.div `
    ${fill_horizontal_all_center};
    width: 100%;
    height: 32px;
    gap: 4px;
  `,
    FaviconLogo: styled.img `
    max-width: 100%;
    max-height: 100%;
    border-radius: 5px;
  `,
    SideBarTitle: styled.div `
    ${fill_horizontal_all_center};
    height: 32px;
    width: 100%;
    background-color: #586377;
    border-radius: 5px;
  `,
};
