import { memo, useEffect } from 'react';
import { signalrHooks2 } from '~/modules/SDK/Signalr/signalrHooks2';
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2';
import { processQuoteToReadable } from '~/utils/processQuoteToReadable';
import { css } from '@emotion/react';
import { fill_horizontal_cross_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import IntradayTrendChart from '~/modules/trendChart';
import StockContribtuionTable from '~/pages/futuresai/monitors/component/StockContribtuionTable';
const QuoteItem = memo(function QuoteItem(props) {
    return (<div css={css `
          ${fill_horizontal_cross_center};
          gap: 6px;
        `}>
        <span>{props.children}</span>
        <span css={css `
            color: ${props.color};
          `}>
          {props.value}
          {props.unit}
        </span>
      </div>);
});
export const InfoContent = memo(function InfoContent() {
    const symbols = ['TXAM-1', 'TSEA'];
    useEffect(() => {
        signalrStore2.addQuote(symbols);
        return () => {
            signalrStore2.removeQuote(symbols);
        };
    }, [JSON.stringify(symbols)]);
    const quotes = signalrHooks2.useQuotes(symbols);
    const quoteTXF = quotes.filter(quote => quote.symbol === 'TXAM-1')[0];
    const quoteTSEA = quotes.filter(quote => quote.symbol === 'TSEA')[0];
    const txamClose = quoteTXF?.close ?? 0;
    const txamOpen = quoteTXF?.open ?? 0;
    const txamLow = quoteTXF?.low ?? 0;
    const txamHigh = quoteTXF?.high ?? 0;
    const txamVolume = quoteTXF?.volume ?? 0;
    const txamPer = quoteTXF?.prevRef ?? 0;
    const txamAmp = txamHigh - txamLow;
    const txamAsk = quoteTXF?.ask ?? 0;
    const txamBid = quoteTXF?.bid ?? 0;
    const quoteChanges = processQuoteToReadable(quoteTXF);
    const tseaClose = quoteTSEA?.close ?? 0;
    const priceDiff = txamClose - tseaClose;
    const closeColor = quoteChanges.closeChangePercent > 0
        ? '#ff0000'
        : quoteChanges.closeChangePercent < 0
            ? '#00ff00'
            : '#ffffff';
    const priceDiffColor = priceDiff > 0 ? '#ff0000' : priceDiff > 0 ? '#00ff00' : '#ffffff';
    const valueColor = (value) => {
        if (value > txamPer) {
            return '#ff0000';
        }
        else if (value < txamPer) {
            return '#00ff00';
        }
        else
            return '#ffffff';
    };
    return (<div css={classes.container}>
      <div css={classes.infoContent}>
        <div css={classes.infoQuote}>
          <span css={css `
              font-weight: bold;
            `}>
            台指期資訊
          </span>
          <span />
          <QuoteItem value={txamClose} color={closeColor}>
            成交
          </QuoteItem>
          <QuoteItem value={txamOpen} color={valueColor(txamOpen)}>
            開盤
          </QuoteItem>
          <QuoteItem value={txamOpen} color={valueColor(txamHigh)}>
            最高
          </QuoteItem>
          <QuoteItem value={txamOpen} color={valueColor(txamLow)}>
            最低
          </QuoteItem>
          <QuoteItem value={quoteChanges.closeChange} color={closeColor}>
            漲跌
          </QuoteItem>
          <QuoteItem value={quoteChanges.closeChangePercent} color={closeColor} unit='%'>
            幅度
          </QuoteItem>
          <QuoteItem value={txamVolume} color={'#ffff00'}>
            總量
          </QuoteItem>
          <QuoteItem value={txamPer} color={'#ff0000'}>
            參考
          </QuoteItem>
          <QuoteItem value={txamAmp} color={'#ffffff'}>
            振幅
          </QuoteItem>
          <QuoteItem value={Number(priceDiff.toFixed(0))} color={priceDiffColor}>
            期現價差
          </QuoteItem>
        </div>
        <div css={classes.infoChart}>
          <IntradayTrendChart symbol={'TXAM-1'} ticksSize={11} ticksHeight={20} priceTicksMargin={-14} priceTicksSize={11}/>
        </div>
      </div>

      <div css={classes.infoContribtuion}>
        <StockContribtuionTable.Display limit={8}/>
      </div>
    </div>);
});
const classes = {
    container: css `
    ${fill_horizontal_cross_center};
    display: grid;
    grid-template-columns: 360px 1fr;
    padding: 4px;
  `,
    infoContent: css `
    ${fill_vertical_cross_center};
    display: grid;
    grid-template-rows: 50% 48%;
  `,
    infoQuote: css `
    ${fill_vertical_cross_center};
    height: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    font-size: 13px;
  `,
    infoChart: css `
    height: 100%;
    width: 100%;
    padding: 4px;
  `,
    infoContribtuion: css `
    height: 100%;
    max-width: 400px !important;
  `,
};
