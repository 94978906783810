import { css } from '@emotion/react';
import FuiButton from '~/pages/heineken_template/components/FuiButton';
import { store } from '~/pages/heineken_template/_private/store';
export const asiajye_initStyling = (templateProps) => {
    store.charting.lightOverrides = {
        'mainSeriesProperties.candleStyle.borderDownColor': '#6ba583',
        'mainSeriesProperties.candleStyle.borderUpColor': '#d75442',
        'mainSeriesProperties.candleStyle.downColor': '#6ba583',
        'mainSeriesProperties.candleStyle.upColor': '#d75442',
        'mainSeriesProperties.candleStyle.wickDownColor': '#555555',
        'mainSeriesProperties.candleStyle.wickUpColor': '#555555',
        'mainSeriesProperties.candleStyle.drawBorder': false,
        'mainSeriesProperties.showPriceLine': false,
        'mainSeriesProperties.showCountdown': true,
        'paneProperties.horzGridProperties.style': 1,
        'paneProperties.vertGridProperties.style': 1,
        'paneProperties.topMargin': 15,
        'paneProperties.bottomMargin': 15,
        'paneProperties.vertGridProperties.color': '#cccccc',
        'paneProperties.horzGridProperties.color': '#cccccc',
        'paneProperties.background': '#ffffff',
        'paneProperties.legendProperties.showStudyValues': true,
        'timeScale.rightOffset': 0,
        'scalesProperties.fontSize': 14,
        'scalesProperties.textColor': '#333333',
        'scalesProperties.lineColor': '#999999',
    };
    templateProps.globalCssset = css `
    #__body {
      ${FuiButton.classes.button.Root} {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 32px;
        border-radius: 5px;
        font-size: 16px;
        cursor: pointer;
        box-shadow: 0px 1px 2px 0.4px #dadada;
      }

      ${FuiButton.classes.button.default.Default} {
        background-color: #fafafa;
        border: 1px solid #cccccc;
        color: #252525;
        &:hover {
          border: 1px solid #aaaaaa;
        }
      }

      ${FuiButton.classes.button.default.Active} {
        background-color: #3061d2;
        border: 1px solid #cccccc;
        color: #fafafa;
        &:hover {
          background-color: #4071d2;
        }
      }

      ${FuiButton.classes.button.long.Default} {
        background-color: #c19795;
        border: 1px solid #cccccc;
        color: #fafafa;
        &:hover {
          background-color: #cb8783;
        }
      }
      ${FuiButton.classes.button.long.Active} {
        background-color: #ce423a;
        border: 1px solid #cccccc;
        color: #fafafa;
        &:hover {
          background-color: #db5149;
        }
      }
      ${FuiButton.classes.button.short.Default} {
        background-color: #95a790;
        border: 1px solid #cccccc;
        color: #fafafa;
        &:hover {
          background-color: #99b392;
        }
      }
      ${FuiButton.classes.button.short.Active} {
        background-color: #48a847;
        border: 1px solid #cccccc;
        color: #fafafa;
        &:hover {
          background-color: #489847;
        }
      }
    }
  `;
};
