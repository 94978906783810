import { memo } from 'react';
import { OptionCalcChart } from '../OptionCalcChart';
import styled from '@emotion/styled';
import { fill_vertical_cross_center } from '~/modules/AppLayout/FlexGridCss';
export const OptionAnalytics = memo(function OptionAnalytics() {
    return (<classes.container>
      <OptionCalcChart />
    </classes.container>);
});
const classes = {
    container: styled.div `
    ${fill_vertical_cross_center};
    width: 480px;
    height: 600px;
  `,
};
