import styled from '@emotion/styled';
import { memo } from 'react';
import { fill_vertical_cross_center } from '~/modules/AppLayout/FlexGridCss';
//試做新版選擇權報價可行性
const optionData = [
    {
        symbol: '16700',
        call: {
            iv: 10,
            delta: 0.87,
            io: 12321,
        },
        put: {
            iv: 10,
            delta: 0.85,
            io: 17544,
        },
    },
    {
        symbol: '16600',
        call: {
            iv: 10,
            delta: 0.88,
            io: 11876,
        },
        put: {
            iv: 10,
            delta: 0.91,
            io: 9955,
        },
    },
];
const DataTableHeader = memo(function DataTableHeader() {
    return (<classes.optionColumn>
      <span>call-delta</span>
      <span>call-io</span>
      <span>call-iv</span>
      <span>symbol</span>
      <span>put-iv</span>
      <span>put-io</span>
      <span>put-delta</span>
    </classes.optionColumn>);
});
const DataTable = memo(function DataTable(props) {
    const data = props.data;
    return (<classes.optionColumn>
      <span>{data.call.delta}</span>
      <span>{data.call.io}</span>
      <span>{data.call.iv}</span>
      <span>{data.symbol}</span>
      <span>{data.put.iv}</span>
      <span>{data.put.io}</span>
      <span>{data.put.delta}</span>
    </classes.optionColumn>);
});
export const OptionQuote = memo(function OptionQuote() {
    return (<classes.container>
      <div css={fill_vertical_cross_center}>
        <DataTableHeader />
        {optionData.map(option => {
            return (<DataTable data={option} key={option.symbol}/>);
        })}
      </div>
      <div>選擇權報價</div>
    </classes.container>);
});
const classes = {
    container: styled.div `
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
  `,
    optionColumn: styled.div `
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    width: 100%;
    height: 32px;
  `,
};
