import styled from '@emotion/styled';
import { memo } from 'react';
import { fill_vertical_all_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import { SymbolPlatformChangeFill } from '~/modules/symbolPlatform/changFill/SymbolPlatformChangeFill';
import { apirc } from '~/configs/apirc';
import QuickStockOrderBox from '~/modules/investment-consultant/orderBox/QuickStockOrderBox';
import useMedia from '~/hooks/useMedia';
import { css } from '@emotion/react';
export const Revive_SidePane1 = memo(function Revive_SidePane1() {
    const origin = apirc.stock.screenerAPI.dailyRank.useSWR({
        order_by: 'desc',
        sort_by: 'change_percent',
        amount_gte: 10e8,
        amount_lte: 10e12,
        volume_gte: 10000,
        volume_lte: 99999,
    }, {
        refreshInterval: 10 * 1000,
        refreshWhenHidden: false,
        refreshWhenOffline: false,
        revalidateOnFocus: false,
        keepPreviousData: true,
    });
    const data = origin?.data?.map(s => s.symbol);
    const { isPc } = useMedia();
    return (<styleds.container>
      <styleds.card height={'calc(100% - 100px)'}>
        <SymbolPlatformChangeFill symbol={data ?? []} watchListGroup1='revive_group_1' watchListGroup2='revive_group_2' watchlistAddType='multiple'/>
      </styleds.card>
      {isPc && (<div css={css `
            width: 100%;
            height: 100px;
            border-radius: 4px;
            background-color: #25262a;
            padding: 4px;
          `}>
          <QuickStockOrderBox.Display />
        </div>)}
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    ${fill_vertical_cross_center};
    background-color: #282a31;
    padding: 8px;
    gap: 2px;
  `,
    card: styled.div `
    ${fill_vertical_all_center};
    height: ${props => props.height};
    background-color: #25262a;
    border-radius: 8px;
  `,
};
