import { isNaN } from 'lodash';
import { SessionType } from '~/modules/SDK/Chart2/SessionType';
import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
import { store } from '~/pages/heineken_template/_private/store';
export const south_iv = createIndicator({
    displayName: '隱含波動率',
    id: '隱含波動率',
    constructorScope: {
        init(context, inputCallback) {
            const ticker = this.PineJS.Std.ticker(this._context)
                .toString()
                .replace(/[^\w-]/g, '');
            const symbol = ticker + '#iv';
            this._context.new_sym(symbol, this.PineJS.Std.period(this._context));
        },
        main(context, inputCallback) {
            context.select_sym(1);
            const dateTime = new Date(this.PineJS.Std.time(this._context));
            const symbol = store.charting.symbol;
            const sessionType = symbol === 'TX-1'
                ? SessionType.TAIFEX
                : symbol.includes('-')
                    ? SessionType.TAIFEX_AM
                    : SessionType.TWSE;
            const iv = this.PineJS.Std.volume(context); // ATM
            const ivHigh = this._context.new_var();
            const ivLow = this._context.new_var();
            ivHigh.get(200);
            ivLow.get(200);
            if (isNaN(ivHigh.get(0)))
                ivHigh.set(0);
            if (isNaN(ivLow.get(0)))
                ivLow.set(0);
            if (dateTime.getHours() >= 9 && dateTime.getHours() < 14) {
                ivHigh.set(ivHigh.get(1));
                ivLow.set(ivLow.get(1));
            }
            else {
                if (this.isSessionFirstBar(sessionType)) {
                    ivHigh.set(iv);
                    ivLow.set(iv);
                }
                else {
                    ivHigh.set(this.maxList([iv, ivHigh.get(1)]));
                    ivLow.set(this.minList([iv, ivLow.get(1)]));
                }
            }
            return [iv ?? NaN, ivHigh.get(0), ivLow.get(0)];
        },
    },
    metainfo: {
        _metainfoVersion: 40,
        scriptIdPart: '',
        is_hidden_study: false,
        is_price_study: false,
        isCustomIndicator: true,
        plots: [
            { id: 'iv', type: 'line' },
            { id: 'high', type: 'line' },
            { id: 'low', type: 'line' },
        ],
        defaults: {
            styles: {
                iv: {
                    linestyle: 0,
                    visible: true,
                    linewidth: 3,
                    plottype: 2,
                    trackPrice: false,
                    transparency: 50,
                    color: '#f8f8f9',
                },
                high: {
                    linestyle: 0,
                    visible: true,
                    linewidth: 3,
                    plottype: 6,
                    trackPrice: false,
                    transparency: 50,
                    color: '#00ff00',
                },
                low: {
                    linestyle: 0,
                    visible: true,
                    linewidth: 3,
                    plottype: 6,
                    trackPrice: false,
                    transparency: 50,
                    color: '#E92410',
                },
            },
            precision: 2,
            inputs: {},
        },
        styles: {
            iv: {
                title: '隱含波動率',
                histogramBase: 0,
            },
            high: {
                title: '隱含波動率-H',
                histogramBase: 0,
            },
            low: {
                title: '隱含波動率-L',
                histogramBase: 0,
            },
        },
        inputs: [],
    },
});
