import { css } from '@emotion/react';
import { Autocomplete } from '@mantine/core';
import { memo, useState } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { fill_horizontal_all_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import { useSymbolDictionaryStore } from '~/modules/SDK/Symbol/useSymbolDictionaryStore';
import { useSymbolWatchList } from '~/modules/WatchList/useSymbolWatchList';
import { removeAfterWhitespace } from '~/modules/symbolPlatform/removeAfterWhitespace';
import { FreemanSymbolChart } from './FreemanSymbolChart';
import { scrollbar2Css } from '~/css/scrollbarCss';
import styled from '@emotion/styled';
import { fr_instrument } from '~/modules/SDK/FrInstrument/FrInstrument';
export const WatchListTrendChartContent = memo(function WatchListTrendChartContent() {
    const { state: watchListState, acts: watchListActs } = useSymbolWatchList('freeman_group_1');
    const data = watchListState.symbolListArray;
    const dictionary = useSymbolDictionaryStore(s => s.dictionary);
    const searchDefaultSymbols = Object.entries(dictionary).map(s => ({
        symbol: s[0],
        name: s[1],
    }));
    const [inputValue, setInputValue] = useState('');
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            const symbolNumber = removeAfterWhitespace(inputValue);
            if (/^\d{4}$/.test(symbolNumber)) {
                watchListActs.addSymbol(symbolNumber, 8);
                setInputValue('');
            }
        }
    };
    return (<classes.container>
      <classes.watchListDashboard>
        <Autocomplete icon={<AiOutlineSearch size={22}/>} placeholder='搜尋商品代號' data={searchDefaultSymbols.map(s => s.symbol + ' ' + s.name)} variant='unstyled' limit={100} maxDropdownHeight={200} value={inputValue} onChange={(value) => setInputValue(value)} onItemSubmit={item => {
            const symbolNumber = removeAfterWhitespace(inputValue);
            if (/^\d{4}$/.test(symbolNumber)) {
                watchListActs.addSymbol(symbolNumber, 8);
                setInputValue('');
            }
        }} onKeyDown={handleKeyDown} css={AutocompleteCss}/>
        <classes.symbolList>
          {data.map(s => {
            const symbolName = fr_instrument.getName(s);
            return (<classes.symbolBody key={s} onClick={() => watchListActs.removeSymbol(s)}>
                <span>{s}</span>
                <span>{symbolName}</span>
              </classes.symbolBody>);
        })}
        </classes.symbolList>
      </classes.watchListDashboard>
      <classes.trendChart>
        {data.map(quote => {
            return (<FreemanSymbolChart key={quote} symbol={quote}/>);
        })}
      </classes.trendChart>
    </classes.container>);
});
const AutocompleteCss = css `
  background-color: #e5e9ed;
  width: 164px;
  border-radius: 4px;
  .mantine-w43e0p {
    color: #252525;
  }
`;
const classes = {
    container: styled.div `
    display: grid;
    grid-template-columns: 168px 1fr;
  `,
    watchListDashboard: styled.div `
    ${fill_vertical_cross_center};
    padding: 4px;
    gap: 4px;
  `,
    symbolList: styled.div `
    ${fill_vertical_cross_center};
    background-color: #313131;
    gap: 2px;
    padding: 2px;
  `,
    symbolBody: styled.div `
    ${fill_horizontal_all_center};
    display: grid;
    grid-template-columns: 30% 70%;
    width: 100%;
    height: 28px;
    padding: 0 8px;
    background-color: #474747;
    border-radius: 2px;
    cursor: pointer;
    font-size: 13px;
    &:hover {
      background-color: #773333;
      color: #ffffff;
      text-decoration: line-through;
    }
  `,
    trendChart: styled.div `
    ${scrollbar2Css};
    display: grid;
    max-width: 100%;
    background-color: #191919;
    border-left: 1px solid #888888;
    overflow: hidden;
    grid-template-columns: repeat(${4}, 1fr);
    gap: 6px;
  `,
};
