import { useEffect, useMemo, useState } from 'react';
import { useSoundStore } from '~/modules/SDK/sound/useSoundStore';
export const useNotification = (symbols) => {
    const sounds = useSoundStore.getState();
    /** 跳出通知視窗 */
    const [enbleNotify, setEnbleNotify] = useState(false);
    /** 變動的商品清單 */
    const [changeSymbols, setChangeSymbols] = useState(['']);
    /** 清單的商品快照 */
    const [longSymbol, setLongSymbol] = useState(['']);
    /** 是否是第一次載入頁面 避免在undefind變成有data時跳出提示 */
    const [initialReady, setInitialReady] = useState(false);
    useEffect(() => {
        // 載入頁面時什麼都不做
        if (symbols === undefined) {
            return;
        }
        // 還沒有商品在清單之中
        if (symbols.length === 0) {
            setLongSymbol([]);
            setInitialReady(true);
        }
        // 有商品在清單之中
        if (symbols.length !== 0) {
            setLongSymbol(symbols);
            setInitialReady(true);
        }
        // 商品清單有新增商品或是移除商品
        const symbolsChanged = symbols.filter(s => !longSymbol.includes(s));
        const isSymbolRemoved = longSymbol.filter(s => !symbols.includes(s));
        //商品清單有新商品加入時
        if (symbolsChanged.length > 0 && initialReady === true) {
            setChangeSymbols(symbolsChanged);
            sounds.playSoundWin168LongDebounced();
            setEnbleNotify(true);
        }
        //商品清單有商品移除時
        if (isSymbolRemoved.length > 0 && initialReady === true) {
            setChangeSymbols(isSymbolRemoved);
            sounds.playSoundWin168LongDebounced();
            setEnbleNotify(true);
        }
    }, [JSON.stringify(symbols), initialReady, longSymbol, sounds, changeSymbols]);
    return useMemo(() => {
        return { state: { enbleNotify, changeSymbols }, acts: { setEnbleNotify } };
    }, [changeSymbols, enbleNotify]);
};
