import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
export const edwin_ma = createIndicator({
    displayName: '均線組合',
    id: 'edwin-ma',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            const in1 = 5;
            const in2 = 20;
            const in3 = 60;
            const in4 = 120;
            const close = this.PineJS.Std.close(this._context);
            const close_array = this._context.new_var(close);
            const sma1 = this.PineJS.Std.sma(close_array, in1, this._context);
            const sma2 = this.PineJS.Std.sma(close_array, in2, this._context);
            const sma3 = this.PineJS.Std.sma(close_array, in3, this._context);
            const sma4 = this.PineJS.Std.sma(close_array, in4, this._context);
            return [sma1, sma2, sma3, sma4];
        },
    },
    metainfo: {
        //宣告
        plots: [
            {
                id: 'plot_0',
                type: 'line',
            },
            {
                id: 'plot_1',
                type: 'line',
            },
            {
                id: 'plot_2',
                type: 'line',
            },
            {
                id: 'plot_3',
                type: 'line',
            },
        ],
        defaults: {
            styles: {
                plot_0: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 0,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: '#e5812f',
                },
                plot_1: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 0,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: '#13cfe5',
                },
                plot_2: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 0,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: '#3be022',
                },
                plot_3: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 0,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: '#ffff00',
                },
            },
            inputs: {},
        },
        styles: {
            plot_0: {
                title: '5ma',
                histogramBase: 0,
            },
            plot_1: {
                title: '20ma',
                histogramBase: 0,
            },
            plot_2: {
                title: '60ma',
                histogramBase: 0,
            },
            plot_3: {
                title: '120ma',
                histogramBase: 0,
            },
        },
        is_price_study: !0,
        inputs: [],
        scriptIdPart: '',
    },
});
