// 多方
// 進場訊號：
// 1. 9點半之後~ 且
// 2. 過9點~9點30分高點 且
// 3. 前30分鐘交易量超過前一天30分鐘交易量的一倍
// 停損：
// 停損用9點到9點30分區間低點
// 停利：
// 1. 獲利2%之後打回來成本出場
// 2. 停損或第一個停利條件沒成立就抱到尾盤13點出場
// 空方
// 進場訊號：
// 1. 9點半後 且
// 2. 破9點~9點30分低點 且
// 3. 前30分鐘交易量超過前一天30分鐘交易量的一倍
// 停損：
// 停損用9點到9點30分區間高點
// 停利：
// 1. 獲利2%之後打回來成本出場
// 2. 停損或第一個停利條件沒成立就抱到尾盤13點出場
import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
import { useChartPositionStore } from '~/modules/cons-misc/mode-buttons/ChartPositionButton';
import { useSoundStore } from '~/modules/SDK/sound/useSoundStore';
const sounds = useSoundStore.getState();
let exit_sound_flag_ = 0;
export const lung88988_strategy2 = createIndicator({
    displayName: 'SUPERSTOCK2',
    id: 'lung88988strategy2',
    constructorScope: {
        init(context, inputCallback) {
            localStorage.setItem('signalInitializedTimestamp', new Date().getTime().toString());
        },
        main(context, inputCallback) {
            const positionType = useChartPositionStore.positionType;
            const high = this.ohlc.high;
            const low = this.ohlc.low;
            const close = this.ohlc.close;
            const open_array = this.ohlc.openArray;
            const high_array = this.ohlc.highArray;
            const low_array = this.ohlc.lowArray;
            const close_array = this.ohlc.closeArray;
            const volume = this.PineJS.Std.volume(this._context);
            const itime = this.PineJS.Std.time(this._context);
            const itime_array = this._context.new_var(itime);
            const ihours = new Date(itime).getHours();
            const iminutes = new Date(itime).getMinutes();
            const entryTime = this._context.new_var();
            const iminutes_array = this._context.new_var(iminutes);
            const volume_array = this._context.new_var();
            const day_end_volume = this._context.new_var();
            const range_volume = this._context.new_var();
            const range_high = this._context.new_var();
            const range_low = this._context.new_var();
            const marketposition = this._context.new_var();
            const entryPrice = this._context.new_var();
            const entryHigh = this._context.new_var();
            const entryLow = this._context.new_var();
            const buy_targetFlag = this._context.new_var();
            const short_targetFlag = this._context.new_var();
            const buy_trade_count = this._context.new_var();
            const short_trade_count = this._context.new_var();
            let buy_icon = NaN;
            let short_icon = NaN;
            let buy_target_icon = 0;
            let short_target_icon = 0;
            let buy_exit_icon = 0;
            let short_exit_icon = 0;
            let buy_timeExit_icon = 0;
            let short_timeExit_icon = 0;
            buy_trade_count.get(0);
            buy_targetFlag.get(1);
            short_targetFlag.get(1);
            open_array.get(1);
            low_array.get(1);
            high_array.get(1);
            close_array.get(1);
            volume_array.get(1);
            marketposition.get(2);
            itime_array.get(2);
            iminutes_array.get(2);
            //重置每日最高、最低、最大單根K棒成交量
            if (ihours === 9 && iminutes === 0) {
                buy_trade_count.set(0);
                short_trade_count.set(0);
                marketposition.set(0);
                //
                range_high.set(high);
                range_low.set(low);
                range_volume.set(volume);
                iminutes_array.set(iminutes);
            }
            //------------------------------------------------
            if (ihours === 9 && iminutes > 0 && ihours === 9 && iminutes <= 30) {
                if (high > range_high.get(0)) {
                    range_high.set(high);
                }
                if (low < range_low.get(0)) {
                    range_low.set(low);
                }
                if (iminutes > iminutes_array.get(0)) {
                    range_volume.set(range_volume.get(0) + volume);
                }
            }
            const startTrade = (ihours === 9 && iminutes > 30) || ihours >= 10 || ihours < 13;
            if (ihours === 13 && iminutes === 30) {
                day_end_volume.set(range_volume.get(0));
            }
            const buy_condition = startTrade &&
                ihours < 13 &&
                buy_trade_count.get(0) < 2 &&
                high > range_high.get(0) &&
                range_volume.get(0) > day_end_volume.get(0) * 2;
            const short_condition = startTrade &&
                ihours < 13 &&
                short_trade_count.get(0) < 2 &&
                low < range_low.get(0) &&
                range_volume.get(0) > day_end_volume.get(0) * 2;
            if (positionType !== 'short' && buy_condition && !short_condition) {
                marketposition.set(1);
            }
            if (marketposition.get(0) === 1 && marketposition.get(1) !== 1) {
                entryPrice.set(close);
                buy_targetFlag.set(0);
                buy_icon = 1;
                buy_trade_count.set(1);
                entryHigh.set(high);
            }
            if (positionType !== 'long' && short_condition && !buy_condition) {
                marketposition.set(-1);
            }
            if (marketposition.get(0) === -1 && marketposition.get(1) !== -1) {
                entryPrice.set(close);
                short_targetFlag.set(0);
                short_icon = 1;
                short_trade_count.set(1);
                entryLow.set(low);
            }
            if (marketposition.get(0) === 1 && marketposition.get(1) === 1) {
                //
                if (high > entryHigh.get(0)) {
                    entryHigh.set(high);
                }
                //跌破0930低點
                if (low < range_low.get(0)) {
                    buy_exit_icon = 1;
                    marketposition.set(0);
                }
                //獲利2%打回成本
                if (entryHigh.get(0) > entryPrice.get(0) * 1.02 && close < entryPrice.get(0)) {
                    buy_target_icon = 1;
                    marketposition.set(0);
                }
                //時間出場
                if (buy_targetFlag.get(0) < 2 && ihours === 13 && iminutes === 25) {
                    buy_timeExit_icon = 1;
                    marketposition.set(0);
                }
            }
            if (marketposition.get(0) === -1 && marketposition.get(1) === -1) {
                //
                if (low > entryLow.get(0)) {
                    entryLow.set(low);
                }
                //漲過0930高點
                if (high > range_high.get(0)) {
                    short_exit_icon = 1;
                    marketposition.set(0);
                }
                //獲利2%打回成本
                if (entryLow.get(0) < entryPrice.get(0) * 0.98 && close > entryPrice.get(0)) {
                    short_target_icon = 1;
                    marketposition.set(0);
                }
                //時間出場
                if (short_targetFlag.get(0) < 2 && ihours === 13 && iminutes === 25) {
                    short_timeExit_icon = 1;
                    marketposition.set(0);
                }
            }
            //聲音------------------------------------------------------------------
            const displayTime = new Date(itime).toLocaleTimeString('en-US');
            const kbarLoadingTimeMs = 10000;
            const isInitialized = new Date().getTime() >
                parseInt(localStorage.getItem('signalInitializedTimestamp') || 'NaN') + kbarLoadingTimeMs;
            const new_bar = this._context.symbol.isNewBar;
            /** 進場部位聲音 */
            if (isInitialized && new_bar) {
                if (marketposition.get(1) === 1 &&
                    marketposition.get(2) !== 1 &&
                    entryTime.get(0) === itime_array.get(1)) {
                    sounds.playSoundWin168LongDebounced();
                    console.log('✅', displayTime, '進多單');
                }
                else if (marketposition.get(1) === -1 &&
                    marketposition.get(2) !== -1 &&
                    entryTime.get(0) === itime_array.get(1)) {
                    sounds.playSoundWin168ShortDebounced();
                    console.log('✅', displayTime, '進空單');
                }
            }
            //出場部位聲音
            if (isInitialized && new_bar) {
                exit_sound_flag_ = 0;
            }
            const long_exit_condition = (buy_target_icon === 1 && exit_sound_flag_ !== 1) ||
                (buy_exit_icon === 1 && exit_sound_flag_ !== 1);
            const short_exit_condition = (short_target_icon === 1 && exit_sound_flag_ !== 1) ||
                (short_exit_icon === 1 && exit_sound_flag_ !== 1);
            if (isInitialized) {
                if (long_exit_condition) {
                    sounds.playSoundWin168ExitDebounced();
                    exit_sound_flag_ = 1;
                    console.log('🚨', displayTime, '當沖多單停利或出場訊號');
                }
                if (short_exit_condition) {
                    sounds.playSoundWin168ExitDebounced();
                    exit_sound_flag_ = 1;
                    console.log('🚨', displayTime, '當沖空單停利或出場訊號');
                }
            }
            return [
                buy_icon,
                buy_target_icon,
                buy_exit_icon,
                buy_timeExit_icon,
                short_icon,
                short_target_icon,
                short_exit_icon,
                short_timeExit_icon,
            ];
        },
    },
    metainfo: {
        is_price_study: !0,
        _metainfoVersion: 42,
        isTVScript: !1,
        isTVScriptStub: !1,
        is_hidden_study: !1,
        plots: [
            {
                id: 'plot_0',
                type: 'chars',
            },
            {
                id: 'plot_1',
                type: 'chars',
            },
            {
                id: 'plot_2',
                type: 'chars',
            },
            {
                id: 'plot_3',
                type: 'chars',
            },
            {
                id: 'plot_4',
                type: 'chars',
            },
            {
                id: 'plot_5',
                type: 'chars',
            },
            {
                id: 'plot_6',
                type: 'chars',
            },
            {
                id: 'plot_7',
                type: 'chars',
            },
        ],
        defaults: {
            styles: {
                plot_0: {
                    linestyle: 0,
                    linewidth: 5,
                    plottype: 8,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: '#ff0000',
                },
                plot_1: {
                    linestyle: 0,
                    linewidth: 5,
                    plottype: 8,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: '#ff0000',
                },
                plot_2: {
                    linestyle: 0,
                    linewidth: 5,
                    plottype: 8,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: '#ff0000',
                },
                plot_3: {
                    linestyle: 0,
                    linewidth: 5,
                    plottype: 8,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: '#ffffff',
                },
                plot_4: {
                    linestyle: 0,
                    linewidth: 5,
                    plottype: 8,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: '#00ff00',
                },
                plot_5: {
                    linestyle: 0,
                    linewidth: 5,
                    plottype: 8,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: '#00ff00',
                },
                plot_6: {
                    linestyle: 0,
                    linewidth: 5,
                    plottype: 8,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: '#00ff00',
                },
                plot_7: {
                    linestyle: 0,
                    linewidth: 5,
                    plottype: 8,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: '#ffffff',
                },
            },
            precision: 2,
            inputs: {},
        },
        styles: {
            plot_0: {
                isHidden: false,
                location: 'BelowBar',
                char: '▲',
                size: 'small',
                text: 'Buy',
            },
            plot_1: {
                isHidden: false,
                location: 'AboveBar',
                char: '*',
                size: 'small',
                text: '保本',
            },
            plot_2: {
                isHidden: false,
                location: 'AboveBar',
                char: '★',
                size: 'small',
                text: '出場',
            },
            plot_3: {
                isHidden: false,
                location: 'AboveBar',
                char: '★',
                size: 'small',
                text: '收盤',
            },
            plot_4: {
                isHidden: false,
                location: 'AboveBar',
                char: '▼',
                size: 'small',
                text: 'short',
            },
            plot_5: {
                isHidden: false,
                location: 'BelowBar',
                char: '*',
                size: 'small',
                text: '保本',
            },
            plot_6: {
                isHidden: false,
                location: 'BelowBar',
                char: '★',
                size: 'small',
                text: '出場',
            },
            plot_7: {
                isHidden: false,
                location: 'AboveBar',
                char: '★',
                size: 'small',
                text: '收盤',
            },
        },
        inputs: [],
    },
});
