import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { flex, pureGrid } from '~/modules/AppLayout/FlexGridCss';
import { Charting } from '~/pages/heineken_template/_private/Charting';
export const Styleds = {
    SideBar: styled.div `
    ${flex.v.crossCenter};
    width: 100%;
    height: calc(100% - 0px);
    background-color: #1e1e1e;
    border-radius: 5px;
    padding: 4px;
    font-size: 16px;
    gap: 4px;
  `,
    SidebarTitle: styled.div `
    ${flex.h.allCenter};
    width: 100%;
    height: 30px;
    background-color: #3a3a3a;
    font-size: 16px;
    border-radius: 5px;
  `,
    TrendChartContainer: styled.div `
    width: 100%;
    height: 170px;
  `,
    StrategyButtonGroup: styled.div `
    ${flex.h.allCenter}
    width:100%;
    height: 26px;
    gap: 4px;
  `,
    PositionButtonGroup: styled.div `
    ${flex.h.allCenter};
    width: 100%;
    height: 26px;
    gap: 4px;
  `,
    ScreenerTypeButtonGroup: styled.div `
    ${flex.h.allCenter};
    width: 100%;
    height: 30px;
    gap: 4px;
  `,
    SymbolListContainer: styled.div `
    width: 100%;
    height: calc(100% - 400px);
    border: 1px solid #555555;
    border-radius: 3px;
  `,
    DataChartContainer: styled.div `
    width: 100%;
    height: 60px;
  `,
    SymbolNameContainer: styled.div `
    ${flex.h.allCenter}
    width: 100%;
    height: 30px;
    background-color: #333333;
    border-radius: 5px;
  `,
    SymbolInfoContainer: styled.div `
    ${flex.h.allCenter};
    width: 100%;
    height: 150px;
  `,
    SymbolRankingGroup: styled.div `
    width: 100%;
    height: 70px;
  `,
    WatchListAddContainer: styled.div `
    ${flex.h.allCenter};
    ${pureGrid};
    width: 100%;
    height: 30px;
    grid-template-columns: 40% 60%;
  `,
    Charting() {
        return (<div css={css `
          ${flex.v.allCenter};
          margin-top: 4px;
          height: calc(100% - 8px);
          border-radius: 5px;
        `}>
        <Charting />
      </div>);
    },
};
