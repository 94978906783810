import { css } from '@emotion/react';
import { useThemeStore } from '~/components/theme/useThemeStore';
import { createIPadCss } from '~/css/createIPadCss';
import { createMobileCss2 } from '~/css/createMobileCss';
import { meCheckHandlerCreateByProductNameWithExpiredAt } from '~/modules/SDK/me/meCheckHandlerCreateByProductName';
import { store } from '~/pages/heineken_template/_private/store';
import { more888_initStrategies } from './more888_initStrategies';
import { more888_initStyling } from './more888_initStyling';
import { More888_SidePane1 } from './more888_SidePane1';
import { More888_SidePane2 } from './more888_SidePane2';
import { More888_Topbar } from './more888_Topbar';
import { InvestmentLoginView } from '~/modules/investment-consultant/loginPage/InvestmentLoginView';
import { useSignalrStart2_0 } from '~/modules/SDK/Signalr/useSignalrStart2_0';
export const more888_init = (templateProps) => {
    more888_initStyling(templateProps);
    more888_initStrategies();
    useThemeStore.setState({ theme: 'dark' });
    store.charting.setThemeMode('dark');
    templateProps.layout.login = (<InvestmentLoginView copyright='(111)金管投顧新字第014號 版權所有© More Rich.摩爾證券投資顧問股份有限公司 All rights reserved.' activationCode={false}/>);
    templateProps.permissions.pageview = meCheckHandlerCreateByProductNameWithExpiredAt('web');
    store.charting.widgetOptions = {
        ...store.charting.widgetOptions,
        symbol: 'TXAM-1',
        interval: '3',
        enableVolumeIndicator: true,
        disabledHeaderWidget: false,
        disabledLeftToolbar: false,
        disabledTimeframesToolbar: true,
        disabledHeaderChartType: true,
        disabledHeaderCompare: true,
        disabledGoToDate: true,
        disabledHeaderSaveload: true,
        overrides: {
            ...store.charting.darkOverrides,
        },
    };
    templateProps.layout.cssset = css `
    grid-template-rows: 48px 1fr;
    grid-template-columns: 336px 1fr 336px;

    user-select: none;

    grid-template-areas:
      'Row1 Row1 Row1'
      'Drawer1 Chart Drawer2'
      'Drawer1 Chart Drawer2';

    ${createIPadCss(css `
      grid-template-rows: 48px 1fr;
      grid-template-columns: 1fr;
      grid-template-areas:
        'Row1 Row1 Row1'
        'Chart Chart Chart';
    `)}

    ${createMobileCss2(css `
      grid-template-rows: 48px 1fr;
      grid-template-columns: 1fr;
      grid-template-areas:
        'Row1 Row1 Row1'
        'Chart Chart Chart';
    `)}
  `;
    templateProps.layout.Row1 = More888_Topbar;
    templateProps.layout.Drawer1 = More888_SidePane1;
    templateProps.layout.Drawer2 = More888_SidePane2;
    templateProps.hooks.add(useSignalrStart2_0);
};
