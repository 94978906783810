import { store } from '../heineken_template/_private/store';
import { freeman_cost } from '~/trades/indicators/freeman/freeman_cost';
import { freeman_diff } from '~/trades/indicators/freeman/freeman_diff';
import { freeman_mid_cost } from '~/trades/indicators/freeman/freeman_mid_cost';
import { freeman_ma } from '~/trades/indicators/freeman/freeman_ma';
import dayAPI from '~/utils/dayAPI';
export const freeman_strategiesGroup = {
    main: [
        {
            displayName: '指標',
            symbol: 'TXAM-1',
            indicators: [freeman_cost, freeman_mid_cost, freeman_ma, freeman_diff],
            interval: '1',
            panesRatio: 80,
            calcFrom: dayAPI().subtract(5, 'day'),
        },
    ],
};
export const freeman_initStrategies = () => {
    store.charting.indicatorsPreloaded = [...store.charting.indicatorsPreloaded, ...[]];
    store.charting.initStrategy({
        configs: [...freeman_strategiesGroup.main],
    });
};
