import styled from '@emotion/styled';
import { memo } from 'react';
import { fill_vertical_all_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import DefaultQuote from './DefaultQuote';
import { scrollbar2Css } from '~/css/scrollbarCss';
import LoadCard from '~/modules/symbolPlatform/LoadCard';
//使用Signalr.ValueOfOHLC[]為基礎 有些情境會在其他地方合併商品或報價 這邊只接受處理完結果
export const DefaultQuoteList = memo(function DefaultQuoteList(props) {
    const qoutes = props.qoutes;
    const symbols = props.symbols;
    const sortGroup = props.sortGroup ?? 1;
    const loading = qoutes.length !== symbols.length;
    return (<styleds.container>
      <styleds.header>
        <DefaultQuote.header sortGroup={sortGroup}/>
      </styleds.header>

      <styleds.body>
        {!loading &&
            qoutes?.map(quote => {
                return (<DefaultQuote.body key={quote.symbol} quote={quote} onClick={event => props.onClick?.()}/>);
            })}
        {loading && <LoadCard.Loading />}
      </styleds.body>
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    ${fill_vertical_cross_center};
    display: grid;
    grid-template-rows: 36px calc(100% - 40px);
    gap: 2px;
  `,
    header: styled.div `
    ${fill_vertical_all_center};
  `,
    body: styled.div `
    ${fill_vertical_cross_center};
    ${scrollbar2Css};
    gap: 2px;
  `,
};
