import { css } from '@emotion/react';
import { memo } from 'react';
import { useSnapshot } from 'valtio';
import { flex } from '~/modules/AppLayout/FlexGridCss';
import { indicatorsValueStore } from './indicatorsValueStore';
export const ValueItem = memo(function ValueItem(props) {
    const name = props.displayName;
    const value = props.value === 'NaN' ? '---' : props.value;
    const color = props.value === 'NaN' ? '#eeeeee' : props.color;
    return (<div css={css `
          ${flex.h.allCenter}
          width:100%;
          height: 25px;
          font-size: 14px;
        `}>
        <div css={css `
            ${flex.h.allCenter}
            width: 50%;
            height: 100%;
            background-color: #34373f;
            border-radius: 5px 0px 0px 5px;
          `}>
          {name}
        </div>
        <div css={css `
            ${flex.h.allCenter}
            width: 50%;
            height: 100%;
            background-color: #3d3d3d;
            border-radius: 0px 5px 5px 0px;
            color: ${color};
          `}>
          {value}
        </div>
      </div>);
});
export const IndicatorsValueForm = memo(function IndicatorsValueForm(props) {
    const isFloat = (n) => {
        return n === n && n !== (n | 0);
    };
    const state = useSnapshot(indicatorsValueStore);
    /** 判斷商品是否有小數 */
    const noFloat = !isFloat(state.close);
    const floatValue = noFloat ? 0 : 2;
    /** 備註： '收盤價'與'商品名稱'掛在 winner_tsea_ma winner_tx_ma winner_os_ma */
    const value1 = state.close.toFixed(floatValue);
    const value2 = state.daytradePower.toFixed(floatValue);
    const value3 = state.trendPower.toFixed(floatValue);
    const value4 = state.constLine.toFixed(floatValue);
    const value5 = state.attackLine.toFixed(floatValue);
    const value6 = state.bigtraderChip.toFixed(floatValue);
    const value7 = state.trendLine[0]?.toFixed(floatValue);
    const value8 = state.bumpyLine[0]?.toFixed(floatValue);
    const value9 = state.bumpyLine[1]?.toFixed(floatValue);
    const value10 = state.trendLine[1]?.toFixed(floatValue);
    return (<div css={css `
          ${flex.h.allCenter}
          width: 100%;
          height: 150px;
          padding: 4px;
          gap: 2.5px;
        `}>
        <div css={css `
            ${flex.v.allCenter}
            height: 100%;
            width: 50%;
            gap: 2.5px;
          `}>
          <ValueItem displayName='現價' value={value1}/>
          <ValueItem displayName='當沖力道' value={value2}/>
          <ValueItem displayName='趨勢力道' value={value3}/>
          <ValueItem displayName='成本線' value={value4}/>
          <ValueItem displayName='攻擊線' value={value5}/>
        </div>
        <div css={css `
            ${flex.v.allCenter}
            height: 100%;
            width: 50%;
            gap: 2.5px;
          `}>
          <ValueItem displayName='大戶籌碼' value={value6} color='#00ffff'/>
          <ValueItem displayName='趨勢對決線' value={value7 || '---'} color='#ff0000'/>
          <ValueItem displayName='盤整防守線' value={value8 || '---'} color='#ff00ff'/>
          <ValueItem displayName='盤整防守線' value={value9 || '---'} color='#00ffff'/>
          <ValueItem displayName='趨勢對決線' value={value10 || '---'} color='#00ff00'/>
        </div>
      </div>);
});
