import { css } from '@emotion/react';
import { useThemeStore } from '~/components/theme/useThemeStore';
import FuiButton from '~/pages/heineken_template/components/FuiButton';
import { store } from '~/pages/heineken_template/_private/store';
export const good178S_initStyling = (templateProps) => {
    store.charting.darkOverrides = {
        'mainSeriesProperties.candleStyle.borderDownColor': '#5aF502',
        'mainSeriesProperties.candleStyle.borderUpColor': '#ff164E',
        'mainSeriesProperties.candleStyle.downColor': '#5aF502',
        'mainSeriesProperties.candleStyle.upColor': '#ff164E',
        'mainSeriesProperties.candleStyle.wickDownColor': '#aaaaaa',
        'mainSeriesProperties.candleStyle.wickUpColor': '#aaaaaa',
        'mainSeriesProperties.candleStyle.drawBorder': false,
        'mainSeriesProperties.showPriceLine': true,
        'mainSeriesProperties.showCountdown': true,
        'paneProperties.horzGridProperties.style': 1,
        'paneProperties.vertGridProperties.style': 1,
        'paneProperties.topMargin': 15,
        'paneProperties.bottomMargin': 15,
        'paneProperties.vertGridProperties.color': '#444444',
        'paneProperties.horzGridProperties.color': '#444444',
        'paneProperties.backgroundType': 'solid',
        'paneProperties.background': '#13151d',
        'timeScale.rightOffset': 0,
        'scalesProperties.fontSize': 14,
        'scalesProperties.textColor': '#cccccc',
        'scalesProperties.lineColor': '#cccccc',
    };
    store.charting.lightOverrides = {
        'mainSeriesProperties.candleStyle.borderDownColor': '#44bb33',
        'mainSeriesProperties.candleStyle.borderUpColor': '#ff5241',
        'mainSeriesProperties.candleStyle.downColor': '#44bb33',
        'mainSeriesProperties.candleStyle.upColor': '#ff5241',
        'mainSeriesProperties.candleStyle.wickDownColor': '#888888',
        'mainSeriesProperties.candleStyle.wickUpColor': '#888888',
        'mainSeriesProperties.candleStyle.drawBorder': false,
        'mainSeriesProperties.showPriceLine': true,
        'mainSeriesProperties.showCountdown': true,
        'paneProperties.horzGridProperties.style': 1,
        'paneProperties.vertGridProperties.style': 1,
        'paneProperties.topMargin': 15,
        'paneProperties.bottomMargin': 15,
        'paneProperties.vertGridProperties.color': '#cccccc',
        'paneProperties.horzGridProperties.color': '#cccccc',
        'paneProperties.backgroundType': 'solid',
        'paneProperties.background': '#ffffff',
        'timeScale.rightOffset': 0,
        'scalesProperties.fontSize': 14,
        'scalesProperties.textColor': '#252525',
        'scalesProperties.lineColor': '#e1e2e3',
    };
    const theme = useThemeStore.getState().theme;
    templateProps.globalCssset = css `
    /* 全局背景色 */
    // #__body .MuiPaper-root {
    //   background-color: ${theme === 'dark' ? '#151720' : '#efefef'};
    // }

    #__body {
      ${FuiButton.classes.button.Root} {
        border-radius: 4px;
        height: 28px;
        font-size: 15px;
        font-weight: 600;
      }

      ${FuiButton.classes.button.default.Default} {
        color: #dddddd;
        background-color: #595e6e;
        border: 1px solid #555555;
        &:hover {
          background-color: #616d7d;
          border: 1px solid #999999;
          color: #eeeeee;
          transition: 0.5s;
        }
      }

      ${FuiButton.classes.button.default.Active} {
        color: #eeeeee;
        background-color: #254053;
        border: 1px solid #eeeeee;
        transition: 0.5s;
        &:hover {
          background-color: #254053dd;
          border: 1px solid #eeeeee;
          color: #eeeeee;
          transition: 0.5s;
        }
      }

      ${FuiButton.classes.button.long.Default} {
        color: #aaaaaa;
        background-color: #bb3340;
        border: 1px solid #555555;
        transition: 0.5s;
        &:hover {
          background-color: #dd3340;
          border: 1px solid #999999;
          color: #bbbbbb;
          transition: 0.5s;
        }
      }
      ${FuiButton.classes.button.long.Active} {
        color: #eeeeee;
        background-color: #dd4450;
        border: 1px solid #eeeeee;
        transition: 0.5s;
        &:hover {
          background-color: #df4450;
          border: 1px solid #eeeeee;
          color: #eeeeee;
          transition: 0.5s;
        }
      }
      ${FuiButton.classes.button.short.Default} {
        color: #aaaaaa;
        background-color: #4a8730;
        border: 1px solid #555555;
        transition: 0.5s;
        &:hover {
          background-color: #4c9730;
          border: 1px solid #999999;
          color: #bbbbbb;
          transition: 0.5s;
        }
      }
      ${FuiButton.classes.button.short.Active} {
        color: #eeeeee;
        background-color: #4ca730;
        border: 1px solid #eeeeee;
        transition: 0.5s;
        &:hover {
          background-color: #4ca730;
          border: 1px solid #eeeeee;
          color: #eeeeee;
          transition: 0.5s;
        }
      }
    }
  `;
};
