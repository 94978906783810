import styled from '@emotion/styled';
import { intersection } from 'lodash';
import { memo } from 'react';
import { useSnapshot } from 'valtio';
import { fill_horizontal_all_center, fill_horizontal_cross_center, fill_vertical_cross_center, jc, } from '~/modules/AppLayout/FlexGridCss';
import { useDailyRankResource } from '~/modules/screener/containers/useStockScreenerResource';
import { SimpleQuoteListInteractWithChart } from '~/modules/symbolQuote/simple';
import { useVirtualAccountOrderPre } from '~/modules/virtualExchange/useVirtualExchangeResource';
import { staticStore } from '~/pages/heineken_template/_private/staticStore';
import { store } from '~/pages/heineken_template/_private/store';
import { asiajyeStore } from '../asiajyeStock_Sidebar';
import { Radio } from '@mantine/core';
export const ConditionPro = memo(function ConditionPro() {
    const state = useSnapshot(asiajyeStore);
    /** 即時訊號商品 */
    const lastTradeString = useSnapshot(staticStore).tradedDate.day0;
    //pre order
    const aiOrderLongEntry = useVirtualAccountOrderPre('asiajye_stock_macd', 'B', {})?.map(a => a.symbol);
    const aiOrderShortEntry = useVirtualAccountOrderPre('asiajye_stock_macd', 'S', {})?.map(a => a.symbol);
    const data = useDailyRankResource({
        date: lastTradeString.format('YYYY-MM-DD'),
        volume_gte: 1000,
        volume_lte: 0,
        amount_gte: 0,
        amount_lte: 0,
        sort_by: 'volume',
        limit: 2000,
    }, 0);
    const allStock = data.data?.map(a => a.symbol);
    const symbolLong = state.showProAllSymboll === true
        ? aiOrderLongEntry
        : intersection(allStock, aiOrderLongEntry).slice(0, 6);
    const symbolShort = state.showProAllSymboll === true
        ? aiOrderShortEntry
        : intersection(allStock, aiOrderShortEntry).slice(0, 6);
    return (<styleds.container>
      <styleds.switchContent>
        <span>標的模式</span>
        <styleds.radioContent>
          <Radio label='全部' color='indigo' checked={state.showProAllSymboll === true} onChange={() => (asiajyeStore.showProAllSymboll = true)}/>
          <Radio label='精選' color='indigo' checked={state.showProAllSymboll === false} onChange={() => (asiajyeStore.showProAllSymboll = false)}/>
        </styleds.radioContent>
      </styleds.switchContent>
      <styleds.listTitle fill='#c85d4a'>多方標的({symbolLong?.length})</styleds.listTitle>
      <styleds.listContent fill='#c85d4a33'>
        <SimpleQuoteListInteractWithChart data={symbolLong ?? []} chart={store.charting} noAutoSubscribe={false}/>
      </styleds.listContent>
      <styleds.listTitle fill='#009900'>空方標的({symbolShort?.length})</styleds.listTitle>
      <styleds.listContent fill='#00990033'>
        <SimpleQuoteListInteractWithChart data={symbolShort ?? []} chart={store.charting} noAutoSubscribe={false}/>
      </styleds.listContent>
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    ${fill_vertical_cross_center};
    display: grid;
    grid-template-rows: 30px 30px calc(50% - 45px) 30px calc(50% - 45px);
  `,
    switchContent: styled.div `
    ${fill_horizontal_cross_center};
    ${jc.spaceBetween};
    font-size: 14px;
    font-weight: 600;
    padding: 0 8px;
  `,
    listTitle: styled.div `
    ${fill_horizontal_all_center};
    background-color: ${props => props.fill};
    color: #ffffff;
    border-radius: 4px;
  `,
    listContent: styled.div `
    height: 100%;
    background-color: ${props => props.fill};
  `,
    radioContent: styled.div `
    ${fill_horizontal_cross_center};
    width: 50%;
    padding: 0 4px;
    justify-content: end;
    gap: 24px;
  `,
};
