import { css } from '@emotion/react';
import { memo, useEffect, useMemo } from 'react';
import { useSnapshot } from 'valtio';
import { fill_horizontal_all_center, fill_horizontal_cross_center, fill_vertical_all_center, fill_vertical_cross_center, flex, } from '~/modules/AppLayout/FlexGridCss';
import { meCheckHandlerAgentWebPro } from '~/modules/SDK/me/meCheckHandlerAgentWeb';
import { asiajye_strategiesGroup } from '~/pages/asiajye/_private/asiajye_initStrategies';
import FuiButton from '~/pages/heineken_template/components/FuiButton';
import col_SoundSwitch from '~/pages/heineken_template/_col/col_SoundSwitch';
import { store } from '~/pages/heineken_template/_private/store';
import { useIndicatorStore } from '~/store/useIndicatorStore';
import { userkd } from '~/trades/indicators/asiajye/userkd';
import { usermacd } from '~/trades/indicators/asiajye/usermacd';
import { asiajyeStore } from './asiajyeStore';
import { AsiajyeLogoCard } from '~/pages/asiajye_stock/_private/component/logoCard';
import styled from '@emotion/styled';
import { Button, Loader, Select } from '@mantine/core';
import QuickFuturesOrderBox from '~/modules/investment-consultant/orderBox/QuickFuturesOrderBox';
import useMedia from '~/hooks/useMedia';
import { scrollbar2Css } from '~/css/scrollbarCss';
import { ComponentBoundary } from '~/modules/SDK/FrError/components/ComponentBoundary';
export const Asiajye_Sidebar = memo(function Sidebar() {
    const { isPc } = useMedia();
    const charting = useSnapshot(store.charting);
    const agentState = useSnapshot(asiajyeStore);
    const entryPrice = useIndicatorStore(state => state.entryPrice) || 0;
    const marketPosition = useIndicatorStore(state => state.marketPosition) || 0;
    const marketPositionDescr = useMemo(() => {
        const isMainSelected = asiajye_strategiesGroup.main.find(config => config.displayName === charting.strategySelected.displayName);
        const isTurningSelected = asiajye_strategiesGroup.turning.find(config => config.displayName === charting.strategySelected.displayName);
        if (isMainSelected && marketPosition === 1)
            return '進場做多';
        if (isMainSelected && marketPosition === -1)
            return '進場做空';
        if (isTurningSelected && marketPosition === 1)
            return '預掛做多';
        if (isTurningSelected && marketPosition === -1)
            return '預掛做空';
    }, [marketPosition, charting.strategySelected]);
    //主要指標狀態
    const displayPositionSelect = charting.strategySelected.displayName.includes('探極') ||
        charting.strategySelected.displayName.includes('閃電');
    useEffect(() => {
        useIndicatorStore.setState({ entryPrice: 0 });
    }, [charting.strategySelected.displayName]);
    return (<styleds.container>
      <styleds.content.logo>
        <AsiajyeLogoCard />
      </styleds.content.logo>

      <col_SoundSwitch.Display />

      <ComponentBoundary filepath='<styleds.content.position.container />'>
        <styleds.content.position.container>
          <styleds.content.position.title>
            {charting.strategySelected.displayName.replace('(特別會員限定)', '')}
          </styleds.content.position.title>
          {!charting.strategySelected.displayName.includes('探極') && (<styleds.content.position.price position={marketPosition}>
              <span>{marketPositionDescr}</span>
              <span css={css `
                  ${flex.h.default};
                `}>
                {entryPrice !== 0 ? (entryPrice) : (<Loader color='blue' size='xs'/>)}
              </span>
            </styleds.content.position.price>)}
        </styleds.content.position.container>
      </ComponentBoundary>

      <ComponentBoundary filepath='<styleds.content.buttonGroup />'>
        <styleds.content.buttonGroup>
          <styleds.buttonMenu>
            <styleds.strategyMain>
              {/* 主要策略集 */}
              {asiajye_strategiesGroup.main.map(config => {
            const isActive = charting.strategySelected.displayName === config.displayName;
            const isProUserOnly = config.displayName.includes('特別會員限定');
            const isProPermission = meCheckHandlerAgentWebPro.check();
            return (<FuiButton.Display key={config.displayName} active={isActive} onClick={event => {
                    if (isProUserOnly && !isProPermission)
                        return;
                    store.charting.setStrategy(config);
                    store.charting.updateFromStrategyState();
                }}>
                    {config.displayName.replace('(特別會員限定)', isProPermission ? '' : '(特別會員限定)')}
                  </FuiButton.Display>);
        })}
            </styleds.strategyMain>
            {/* 短線策略集 */}
            <ComponentBoundary filepath='短線策略集'>
              <styleds.strategySub>
                {asiajye_strategiesGroup.turning.map(config => {
            const isActive = charting.strategySelected.displayName === config.displayName;
            return (<FuiButton.Display key={config.displayName} active={isActive} onClick={event => {
                    store.charting.setStrategy(config);
                    if (config.displayName === '轉折多空') {
                        asiajyeStore.turningTrade.priceValue =
                            agentState.turningTrade.turningMP === 1 ? 0.99667 : 1.00333;
                        asiajyeStore.turningTrade.assistPriceValue = 17;
                    }
                    if (config.displayName === '短線轉折') {
                        asiajyeStore.turningTrade.priceValue =
                            agentState.turningTrade.turningMP === 1 ? 0.99667 : 1.00333;
                        asiajyeStore.turningTrade.assistPriceValue = 0;
                    }
                    if (config.displayName === '天地線1') {
                        asiajyeStore.turningTrade.priceValue =
                            agentState.turningTrade.turningMP === 1 ? 0.994745 : 1.005255;
                        asiajyeStore.turningTrade.assistPriceValue = 0;
                    }
                    if (config.displayName === '短波轉折') {
                        asiajyeStore.turningTrade.priceValue =
                            agentState.turningTrade.turningMP === 1 ? 0.99282 : 1.00718;
                        asiajyeStore.turningTrade.assistPriceValue = 0;
                    }
                    if (config.displayName === '天地線2') {
                        asiajyeStore.turningTrade.priceValue =
                            agentState.turningTrade.turningMP === 1 ? 0.990855 : 1.009145;
                        asiajyeStore.turningTrade.assistPriceValue = 0;
                    }
                    if (config.displayName === '長波轉折') {
                        asiajyeStore.turningTrade.priceValue =
                            agentState.turningTrade.turningMP === 1 ? 0.98889 : 1.01111;
                        asiajyeStore.turningTrade.assistPriceValue = 0;
                    }
                    store.charting.updateFromStrategyState();
                }}>
                      {config.displayName}
                    </FuiButton.Display>);
        })}
              </styleds.strategySub>
            </ComponentBoundary>

            {/* 圖表做單方向 */}
            <ComponentBoundary filepath='圖表做單方向'>
              <styleds.strategyPosition>
                {!displayPositionSelect && (<div css={css `
                      ${fill_horizontal_all_center};
                      gap: 4px;
                    `}>
                    <FuiButton.Display variant='long' active={agentState.turningTrade.turningMP === 1} onClick={event => {
                const displayName = charting.strategySelected.displayName;
                asiajyeStore.turningTrade.turningMP = 1;
                asiajyeStore.turningTrade.position = 1;
                if (displayName === '轉折多空') {
                    asiajyeStore.turningTrade.priceValue = 0.99667;
                    asiajyeStore.turningTrade.assistPriceValue = 17;
                }
                if (displayName === '短線轉折') {
                    asiajyeStore.turningTrade.priceValue = 0.99667;
                    asiajyeStore.turningTrade.assistPriceValue = 0;
                }
                if (displayName === '天地線1') {
                    asiajyeStore.turningTrade.priceValue = 0.994745;
                    asiajyeStore.turningTrade.assistPriceValue = 0;
                }
                if (displayName === '短波轉折') {
                    asiajyeStore.turningTrade.priceValue = 0.99282;
                    asiajyeStore.turningTrade.assistPriceValue = 0;
                }
                if (displayName === '天地線2') {
                    asiajyeStore.turningTrade.priceValue = 0.990855;
                    asiajyeStore.turningTrade.assistPriceValue = 0;
                }
                if (displayName === '長波轉折') {
                    asiajyeStore.turningTrade.priceValue = 0.98889;
                    asiajyeStore.turningTrade.assistPriceValue = 0;
                }
                store.charting.updateFromState();
            }}>
                      多
                    </FuiButton.Display>

                    <FuiButton.Display variant='short' active={agentState.turningTrade.turningMP === -1} onClick={event => {
                const displayName = charting.strategySelected.displayName;
                asiajyeStore.turningTrade.turningMP = -1;
                asiajyeStore.turningTrade.position = -1;
                if (displayName === '轉折多空') {
                    asiajyeStore.turningTrade.priceValue = 1.00333;
                    asiajyeStore.turningTrade.assistPriceValue = 17;
                }
                if (displayName === '短線轉折') {
                    asiajyeStore.turningTrade.priceValue = 1.00333;
                    asiajyeStore.turningTrade.assistPriceValue = 0;
                }
                if (displayName === '天地線1') {
                    asiajyeStore.turningTrade.priceValue = 1.005255;
                    asiajyeStore.turningTrade.assistPriceValue = 0;
                }
                if (displayName === '短波轉折') {
                    asiajyeStore.turningTrade.priceValue = 1.00718;
                    asiajyeStore.turningTrade.assistPriceValue = 0;
                }
                if (displayName === '天地線2') {
                    asiajyeStore.turningTrade.priceValue = 1.009145;
                    asiajyeStore.turningTrade.assistPriceValue = 0;
                }
                if (displayName === '長波轉折') {
                    asiajyeStore.turningTrade.priceValue = 1.01111;
                    asiajyeStore.turningTrade.assistPriceValue = 0;
                }
                store.charting.updateFromState();
            }}>
                      空
                    </FuiButton.Display>
                  </div>)}
              </styleds.strategyPosition>
            </ComponentBoundary>
          </styleds.buttonMenu>
        </styleds.content.buttonGroup>
      </ComponentBoundary>

      {/* 單獨性指標功能 */}
      <styleds.strategyIndicators>
        <Select label='副圖指標選擇' data={[
            { value: 'none', label: '請選擇指標' },
            { value: 'userkd', label: 'KD指標' },
            { value: 'usermacd', label: 'MACD指標' },
            { value: 'all', label: 'KD+MACD指標' },
        ]} defaultValue={'none'} value={agentState.indicatorsSelected} onChange={value => {
            asiajyeStore.indicatorsSelected = value;
            const setIndicators = () => {
                if (value === 'userkd')
                    return [userkd];
                else if (value === 'usermacd')
                    return [usermacd];
                else if (value === 'all')
                    return [userkd, usermacd];
                else if (value === 'none')
                    return [];
                else
                    return [];
            };
            store.charting.replaceIndicators([
                ...store.charting.strategySelected.indicators,
                ...setIndicators(),
            ]);
        }}/>
        <Button css={css `
            width: 116px;
            height: 40px;
          `} variant='filled' color='red' onClick={event => {
            asiajyeStore.indicatorsSelected = 'none';
            store.charting.removeIndicators([userkd, usermacd]);
        }}>
          移除指標
        </Button>
      </styleds.strategyIndicators>

      {isPc && (<styleds.content.oederBox>
          <QuickFuturesOrderBox.Display />
        </styleds.content.oederBox>)}
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    ${fill_vertical_cross_center};
    height: calc(100vh - 0px);
    padding: 4px;
    gap: 4px;
    border-right: 1px solid #dcdfe6;
  `,
    content: {
        logo: styled.div `
      ${fill_vertical_all_center};
      height: 124px;
    `,
        display: styled.div `
      ${fill_horizontal_all_center};
      height: 32px;
    `,
        buttonGroup: styled.div `
      ${fill_vertical_cross_center};
      ${scrollbar2Css};
      height: auto;
      max-height: 376px;
      gap: 4px;
    `,
        oederBox: styled.div `
      ${fill_vertical_all_center};
      width: 100%;
      height: 116px;
      padding: 4px;
      border-radius: 5px;
      box-shadow: 0px 1px 2px 1px #dadada;
    `,
        position: {
            container: styled.div `
        ${fill_vertical_all_center};
        height: 86px;
        border-radius: 5px;
        border: 1px solid #aaaaaa;
        padding: 8px;
      `,
            title: styled.div `
        ${fill_horizontal_all_center};
        width: 80%;
        height: 36px;
        color: #eeeeee;
        background-color: #142d94;
        border-radius: 5px;
        font-size: 20px;
        font-weight: 600;
      `,
            price: styled.div `
        ${fill_horizontal_all_center};
        height: 36px;
        font-size: 18px;
        font-weight: 600;
        color: ${props => props.position === 1 ? '#cc2222' : props.position === -1 ? '#00aa00' : '#454545'};
      `,
        },
    },
    buttonMenu: styled.div `
    ${flex.v.allCenter};
    width: 100%;
    gap: 4px;
  `,
    strategyMain: styled.div `
    ${fill_vertical_cross_center};
    height: 112px;
    background-color: #f7f7f7;
    border-radius: 4px;
    padding: 4px;
    gap: 4px;
  `,
    strategySub: styled.div `
    ${fill_vertical_cross_center};
    height: 220px;
    background-color: #f7f7f7;
    border-radius: 4px;
    padding: 4px;
    gap: 4px;
  `,
    strategyPosition: styled.div `
    ${fill_vertical_cross_center};
    height: 32px;
    gap: 4px;
  `,
    strategyIndicators: styled.div `
    ${fill_horizontal_cross_center};
    height: 64px;
    align-items: end;
    gap: 4px;
  `,
    /** # 掛上特定指標組，掛到目前的策略組，並呈現到圖表上 */
    AppendIndicatorsFuiButton: memo(function AppendIndicatorsButton(props) {
        useSnapshot(store.charting).indicators;
        const isActive = store.charting.isIndicatorsIncludes(props.indicators);
        return (<FuiButton.Display active={isActive} onClick={event => {
                store.charting.replaceIndicators([
                    ...store.charting.strategySelected.indicators,
                    ...props.indicators,
                ]);
            }}>
        {props.children}
      </FuiButton.Display>);
    }),
};
