import styled from '@emotion/styled';
import { memo, useEffect } from 'react';
import { scrollbar2Css } from '~/css/scrollbarCss';
import { useFirebaseValue } from '~/hooks/useFirebaseValue';
import { fill_vertical_cross_center } from '~/modules/AppLayout/FlexGridCss';
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2';
import StockFuturesSymbol from '~/pages/daddy960_stocknerve/component/StockFuturesSymbol';
import { store } from '~/pages/heineken_template/_private/store';
import { wu5868_strategiesDayTradeGroup } from '../wu5868_initStrategies';
export const StockFirebaseShort = memo(function StockFirebaseShort() {
    const shortFirebase = useFirebaseValue('wu5868_signal', 'short');
    const symbol = shortFirebase?.symbols;
    useEffect(() => {
        signalrStore2.addQuote(symbol);
        return () => {
            signalrStore2.removeQuote(symbol);
        };
    }, [JSON.stringify(symbol)]);
    //const { state, acts } = useNotification(symbol)
    return (<styleds.container>
      <styleds.headerContent>
        <StockFuturesSymbol.header />
      </styleds.headerContent>

      <styleds.lsitContent>
        {symbol?.map(s => {
            return (<StockFuturesSymbol.body key={s} symbol={s} handleClick={() => {
                    store.charting.setStrategy(wu5868_strategiesDayTradeGroup.main[0]);
                    store.charting.updateFromStrategyState();
                }}/>);
        })}
      </styleds.lsitContent>
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    ${fill_vertical_cross_center};
  `,
    headerContent: styled.div `
    width: 100%;
    height: 36px;
  `,
    lsitContent: styled.div `
    ${scrollbar2Css};
    height: calc(100% - 36px);
  `,
};
