export const baseQuoteStyled = (isLimit, change, styledsConfig) => {
    if (isLimit === true) {
        if (change > 0) {
            return styledsConfig.riseLimitStyled;
        }
        if (change < 0) {
            return styledsConfig.fallLimitStyled;
        }
    }
    else if (change > 0) {
        return styledsConfig.riseStyled;
    }
    else if (change < 0) {
        return styledsConfig.fallStyled;
    }
    return styledsConfig.flatStyled;
};
export const baseSelectedStyled = (selected, selectedConfig) => {
    if (selected === true) {
        return selectedConfig.selectedStyled;
    }
    else
        return selectedConfig.noSelectedStyled;
};
