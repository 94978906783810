import { store } from '~/pages/heineken_template/_private/store';
import { winner_tsea_ma } from '~/trades/indicators/winner/winner_tsea_ma';
import { winner_tsea_kd } from '~/trades/indicators/winner/winner_tsea_kd';
import { winner_tsea_volume } from '~/trades/indicators/winner/winner_tsea_volume';
import { winner_tx_ma } from '~/trades/indicators/winner/winner_tx_ma';
import { winner_tx_attack } from '~/trades/indicators/winner/winner_tx_attack';
import { winner_tx_kbar } from '~/trades/indicators/winner/winner_tx_kbar';
import { winner_tx_trend } from '~/trades/indicators/winner/winner_tx_trend';
import { winner_tx_bisa } from '~/trades/indicators/winner/winner_tx_bisa';
import { winner_tx_big } from '~/trades/indicators/winner/winner_tx_big';
import { winner_tx_powerline } from '~/trades/indicators/winner/winner_tx_powerline';
import { winner_tx_powerline2 } from '~/trades/indicators/winner/winner_tx_powerline2';
import { winner_all_diamond } from '~/trades/indicators/winner/winner_all_diamond';
import { winner_tx_priceline618 } from '~/trades/indicators/winner/winner_tx_priceline618';
import { winner_tx_priceline309 } from '~/trades/indicators/winner/winner_tx_priceline309';
import { winner_test_ti } from '~/trades/indicators/winner/winner_test_ti';
import { winner_test_bs0 } from '~/trades/indicators/winner/winner_test_bs0';
import { winner_test_bsti } from '~/trades/indicators/winner/winner_test_bsti';
import dayAPI from '~/utils/dayAPI';
const tx_indicators_default1 = [winner_tsea_ma, winner_tsea_volume, winner_tsea_kd];
const tx_indicators_power1 = [winner_tsea_ma, winner_tsea_volume, winner_tsea_kd, winner_tx_attack];
const tx_indicators_default2 = [
    winner_tx_bisa, //乖離
    winner_tx_powerline, //力道線
    winner_tx_powerline2, //海期改力道線
    winner_tx_kbar, //K棒變色
    winner_tx_priceline618, //價格線
    winner_tx_priceline309, //價格線
    winner_tx_ma, //大均
    winner_tx_trend, //副圖1
    winner_test_bsti, //副圖2
    winner_tx_big, //副圖3
    winner_all_diamond, //鑽石
];
const tx_indicators_power2 = [
    winner_tx_bisa, //乖離
    winner_tx_powerline, //力道線
    winner_tx_powerline2, //海期改力道線
    winner_tx_attack, //攻擊線
    winner_tx_kbar, //K棒變色
    winner_tx_priceline618, //價格線
    winner_tx_priceline309, //價格線
    winner_tx_ma, //大均
    winner_tx_trend, //副圖1
    winner_test_bsti, //副圖2
    winner_tx_big, //副圖3
    winner_all_diamond, //鑽石
];
const power_default = [winner_test_ti, winner_test_bs0, winner_test_bsti];
const power_power1 = [winner_test_ti, winner_test_bs0, winner_test_bsti, winner_tx_attack];
export const winnerB_initStrategies = () => {
    store.charting.initStrategy({
        configs: [
            {
                displayName: '加權指數',
                indicators: tx_indicators_default1,
                symbol: 'TSEA',
                interval: '5',
                calcFrom: dayAPI().subtract(14, 'day'),
                panesRatio: 66,
            },
            {
                displayName: '台指期',
                indicators: tx_indicators_default2,
                symbol: 'TX-1',
                interval: '5',
                calcFrom: dayAPI().subtract(14, 'day'),
                panesRatio: 66,
            },
            {
                displayName: '台指籌碼',
                indicators: power_default,
                symbol: 'TX-1',
                interval: '5',
                calcFrom: dayAPI().subtract(14, 'day'),
                panesRatio: 66,
            },
        ],
        //noDefaults: true,
    });
};
