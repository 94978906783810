/* eslint-disable no-var */
import { useSoundStore } from '~/modules/SDK/sound/useSoundStore';
import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
const sounds = useSoundStore.getState();
export const sg3 = createIndicator({
    id: 'sg3',
    displayName: '出手(短)',
    filterOnTheme: 'dark',
    enabledOn(symbol, data, channel) {
        return (symbol.includes('-') || data?.type === channel.os_futures || data?.type === channel.tw_futures);
    },
    constructorScope: {
        init(context, inputCallback) {
            localStorage.setItem('signalInitializedTimestamp', new Date().getTime().toString());
        },
        main(context, inputCallback) {
            const donchian = (period) => {
                const lowVar = context.new_var(this.ohlc.low);
                const highVar = context.new_var(this.ohlc.high);
                const value = this.PineJS.Std.avg(this.PineJS.Std.lowest(lowVar, period, context), this.PineJS.Std.highest(highVar, period, context));
                return value;
            };
            const f_1 = () => {
                var e = 3 /*this._input(0)*/, t = 14 /*this._input(1)*/, i = 17 /*this._input(2)*/, o = 4 /*this._input(3)*/, r = donchian(e), s = donchian(t), a = this.PineJS.Std.avg(r, s), l = donchian(i), c = this.ohlc.close, //this.PineJS.Std.close(this._context),
                u = this.PineJS.Std.gt(a, l) ? 0 : 1;
                return [r, s, c, a, l, -o, o, o, u];
            };
            var len = 1;
            var pv0 = NaN;
            var pv1 = NaN;
            var pvflag = this._context.new_var();
            var pb1 = NaN;
            var pb2 = NaN;
            var pb3 = NaN;
            var pb4 = NaN;
            var pb5 = NaN;
            var ps1 = NaN;
            var ps2 = NaN;
            var ps3 = NaN;
            var ps4 = NaN;
            var ps5 = NaN;
            var h = this.ohlc.high; // this.PineJS.Std.high(this._context)
            var l = this.ohlc.low; // this.PineJS.Std.low(this._context)
            var c = this.ohlc.close; // this.PineJS.Std.close(this._context)
            var t = this.PineJS.Std.time(this._context);
            var nt = this._context.new_var(t);
            var r = f_1();
            var r0 = this._context.new_var(r[0]);
            var r1 = this._context.new_var(r[1]);
            var r2 = this._context.new_var(r[2]);
            var r3 = this._context.new_var(r[3]);
            var r4 = this._context.new_var(r[4]);
            var ichi_mm = r1.get(0);
            var ichi_ll = Math.min(r3.get(13), r4.get(13));
            var ichi_hh = Math.max(r3.get(13), r4.get(13));
            var entryPrice = this._context.new_var();
            var nh = this._context.new_var(h);
            var nl = this._context.new_var(l);
            var nc = this._context.new_var(c);
            nl.get(1);
            nh.get(1);
            var hh = this._context.new_var(this.PineJS.Std.highest(this._context.new_var(h), len, this._context));
            var ll = this._context.new_var(this.PineJS.Std.lowest(this._context.new_var(l), len, this._context));
            var bDt = this._context.new_var(); //紀錄部位成立的時間
            var pvDt = this._context.new_var(); //紀錄停利訊號成立的時間
            var localTimeVar = this._context.new_var();
            const timeDeltaMs = 500;
            const kbarLoadingTimeMs = 10000;
            const kbarIntervalMs = this.PineJS.Std.interval(this._context) * 60 * 1000;
            const isInitialized = new Date().getTime() >
                parseInt(localStorage.getItem('signalInitializedTimestamp') || 'NaN') + kbarLoadingTimeMs;
            const isBarChanging = isNaN(localTimeVar.get(1)) ||
                Math.abs(new Date().getTime() - localTimeVar.get(1)) < timeDeltaMs;
            localTimeVar.set(new Date().getTime());
            nt.get(1);
            bDt.get(0);
            bDt.get(1);
            pvDt.get(0);
            pvDt.get(1);
            this.bs.position.get(1);
            this.bs.position.get(2);
            pvflag.get(1);
            pvflag.get(2);
            if (c > ichi_mm && c > ichi_hh) {
                this.bs.buy();
                // bState.set(1)
            }
            else if (c < ichi_mm && c < ichi_ll) {
                this.bs.sell();
                // bState.set(-1)
            }
            if (this.bs.isPositionJustBuy) {
                pv0 = 1;
                pv1 = NaN;
                entryPrice.set(c);
                pvflag.set(0);
                bDt.set(t);
            }
            else if (this.bs.isPositionJustSell) {
                pv0 = NaN;
                pv1 = 1;
                entryPrice.set(c);
                pvflag.set(0);
                bDt.set(t);
            }
            var p1 = 0.003;
            var p2 = 0.005;
            var p3 = 0.01;
            var p4 = 0.015;
            var p5 = 0.02;
            if (this.bs.isPositionBuying) {
                if (pvflag.get(0) === 0 && h > entryPrice * (1 + p1) && nh.get(1) < entryPrice * (1 + p1)) {
                    pvflag.set(1), (pb1 = 1), pvDt.set(t);
                }
                else if (pvflag.get(0) === 1 && h > entryPrice * (1 + p2)) {
                    pvflag.set(2), (pb2 = 1), pvDt.set(t);
                }
                else if (pvflag.get(0) === 2 && h > entryPrice * (1 + p3)) {
                    pvflag.set(3), (pb3 = 1), pvDt.set(t);
                }
                else if (pvflag.get(0) === 3 && h > entryPrice * (1 + p4)) {
                    pvflag.set(4), (pb4 = 1), pvDt.set(t);
                }
                else if (pvflag.get(0) === 4 && h > entryPrice * (1 + p5)) {
                    pvflag.set(5), (pb5 = 1), pvDt.set(t);
                }
            }
            else if (this.bs.isPositionSelling) {
                if (pvflag.get(0) === 0 && l < entryPrice * (1 - p1) && nl.get(1) > entryPrice * (1 - p1)) {
                    pvflag.set(1), (ps1 = 1), pvDt.set(t);
                }
                else if (pvflag.get(0) === 1 && l < entryPrice * (1 - p2)) {
                    pvflag.set(2), (ps2 = 1), pvDt.set(t);
                }
                else if (pvflag.get(0) === 2 && l < entryPrice * (1 - p3)) {
                    pvflag.set(3), (ps3 = 1), pvDt.set(t);
                }
                else if (pvflag.get(0) === 3 && l < entryPrice * (1 - p4)) {
                    pvflag.set(4), (ps4 = 1), pvDt.set(t);
                }
                else if (pvflag.get(0) === 4 && l < entryPrice * (1 - p5)) {
                    pvflag.set(5), (ps5 = 1), pvDt.set(t);
                }
            }
            /*Log--------------------------*/
            const LogExitText = '[部位出場] ' +
                new Date().getHours() +
                ':' +
                (new Date().getMinutes() < 10 ? '0' : '') +
                new Date().getMinutes() +
                ':' +
                (new Date().getSeconds() < 10 ? '0' : '') +
                new Date().getSeconds() +
                '\n' +
                '[訊號]' +
                new Date(nt.get(1)).getHours() +
                ':' +
                (new Date(nt.get(1)).getMinutes() < 10 ? '0' : '') +
                new Date(nt.get(1)).getMinutes() +
                ' [聲音]' +
                new Date(t).getHours() +
                ':' +
                (new Date(t).getMinutes() < 10 ? '0' : '') +
                new Date(t).getMinutes() +
                '\n' +
                '停利階段 ' +
                pvflag.get(0) +
                '\n' +
                '----------------------';
            const LogEntryText = '[部位進場] ' +
                new Date().getHours() +
                ':' +
                (new Date().getMinutes() < 10 ? '0' : '') +
                new Date().getMinutes() +
                ':' +
                (new Date().getSeconds() < 10 ? '0' : '') +
                new Date().getSeconds() +
                '\n' +
                '[訊號]' +
                new Date(nt.get(1)).getHours() +
                ':' +
                (new Date(nt.get(1)).getMinutes() < 10 ? '0' : '') +
                new Date(nt.get(1)).getMinutes() +
                ' [聲音]' +
                new Date(t).getHours() +
                ':' +
                (new Date(t).getMinutes() < 10 ? '0' : '') +
                new Date(t).getMinutes() +
                '\n' +
                '部位 ' +
                this.bs.position.get(0) + // bState.get(0) +
                '\n' +
                '----------------------';
            /*進場聲音--------------------------*/
            if (isInitialized && isBarChanging) {
                if (this.bs.position.get(1) === 1 &&
                    this.bs.position.get(2) !== 1 &&
                    bDt.get(0) === nt.get(1)) {
                    sounds.playSoundVictoryDebounced();
                    console.log(LogEntryText);
                }
                else if (this.bs.position.get(1) === -1 &&
                    this.bs.position.get(2) !== -1 &&
                    bDt.get(0) === nt.get(1)) {
                    sounds.playSoundVictoryDebounced();
                    console.log(LogEntryText);
                }
            }
            /*出場條件--------------------------*/
            const isExitCondition = (pvflag.get(1) === 1 && pvflag.get(2) === 0 && pvDt.get(0) === nt.get(1)) ||
                (pvflag.get(1) === 2 && pvflag.get(2) === 1 && pvDt.get(0) === nt.get(1)) ||
                (pvflag.get(1) === 3 && pvflag.get(2) === 2 && pvDt.get(0) === nt.get(1)) ||
                (pvflag.get(1) === 4 && pvflag.get(2) === 3 && pvDt.get(0) === nt.get(1)) ||
                (pvflag.get(1) === 5 && pvflag.get(2) === 4 && pvDt.get(0) === nt.get(1));
            /*出場聲音--------------------------*/
            if (isInitialized && isBarChanging) {
                if (isExitCondition) {
                    sounds.playSoundMagicDebounced();
                    console.log(LogExitText);
                }
            }
            return [pv0, pv1, pb1, pb2, pb3, pb4, pb5, ps1, ps2, ps3, ps4, ps5];
        },
    },
    metainfo: {
        _metainfoVersion: 27,
        isTVScript: false,
        isTVScriptStub: false,
        is_hidden_study: false,
        visible: false,
        isCustomIndicator: true,
        defaults: {
            styles: {
                plot_0: {
                    color: '#FF0000',
                    textColor: '#FF0000',
                    transparency: 20,
                    visible: true,
                },
                plot_1: {
                    color: '#2196F3',
                    textColor: '#2196F3',
                    transparency: 10,
                    visible: true,
                },
                plot_b1: {
                    color: '#CFBF00',
                    textColor: '#CFBF00',
                    transparency: 10,
                    visible: true,
                },
                plot_b2: {
                    color: '#FFEE00',
                    textColor: '#FFEE00',
                    transparency: 10,
                    visible: true,
                },
                plot_b3: {
                    color: '#EE00FF',
                    textColor: '#EE00FF',
                    transparency: 10,
                    visible: true,
                },
                plot_b4: {
                    color: '#FF0068',
                    textColor: '#FF0068',
                    transparency: 10,
                    visible: true,
                },
                plot_b5: {
                    color: '#FFEE00',
                    textColor: '#FFEE00',
                    transparency: 10,
                    visible: true,
                },
                plot_s1: {
                    color: '#209048',
                    textColor: '#209048',
                    transparency: 20,
                    visible: true,
                },
                plot_s2: {
                    color: '#55FFEE',
                    textColor: '#55FFEE',
                    transparency: 20,
                    visible: true,
                },
                plot_s3: {
                    color: '#00FF55',
                    textColor: '#00FF55',
                    transparency: 20,
                    visible: true,
                },
                plot_s4: {
                    color: '#209048',
                    textColor: '#209048',
                    transparency: 20,
                    visible: true,
                },
                plot_s5: {
                    color: '#55FFEE',
                    textColor: '#55FFEE',
                    transparency: 20,
                    visible: true,
                },
            },
            inputs: {
            /*in_0: 60*/
            },
        },
        plots: [
            {
                id: 'plot_0',
                type: 'shapes',
            },
            {
                id: 'plot_1',
                type: 'shapes',
            },
            {
                id: 'plot_b1',
                type: 'chars',
            },
            {
                id: 'plot_b2',
                type: 'chars',
            },
            {
                id: 'plot_b3',
                type: 'chars',
            },
            {
                id: 'plot_b4',
                type: 'chars',
            },
            {
                id: 'plot_b5',
                type: 'chars',
            },
            {
                id: 'plot_s1',
                type: 'chars',
            },
            {
                id: 'plot_s2',
                type: 'chars',
            },
            {
                id: 'plot_s3',
                type: 'chars',
            },
            {
                id: 'plot_s4',
                type: 'chars',
            },
            {
                id: 'plot_s5',
                type: 'chars',
            },
        ],
        styles: {
            plot_0: {
                isHidden: false,
                location: 'BelowBar', // Top, Bottom
                plottype: 'shape_triangle_up',
                size: 'normal',
                text: '',
                title: 'Shapes',
            },
            plot_1: {
                isHidden: false,
                location: 'AboveBar', // Top, Bottom
                plottype: 'shape_triangle_down',
                size: 'normal',
                text: '',
                title: 'Shapes',
            },
            plot_b1: {
                isHidden: false,
                location: 'AboveBar', // Top, Bottom
                char: '*',
                size: 'small',
                title: 'Shapes',
            },
            plot_b2: {
                isHidden: false,
                location: 'AboveBar', // Top, Bottom
                char: '+',
                size: 'small',
                title: 'Shapes',
            },
            plot_b3: {
                isHidden: false,
                location: 'AboveBar', // Top, Bottom
                char: '$',
                size: 'small',
                title: 'Shapes',
            },
            plot_b4: {
                isHidden: false,
                location: 'AboveBar', // Top, Bottom
                char: '$',
                size: 'small',
                title: 'Shapes',
            },
            plot_b5: {
                isHidden: false,
                location: 'AboveBar', // Top, Bottom
                char: '$',
                size: 'small',
                title: 'Shapes',
            },
            plot_s1: {
                isHidden: false,
                location: 'BelowBar', // Top, Bottom
                char: '*',
                size: 'small',
                title: 'Shapes',
            },
            plot_s2: {
                isHidden: false,
                location: 'BelowBar', // Top, Bottom
                char: '+',
                size: 'small',
                title: 'Shapes',
            },
            plot_s3: {
                isHidden: false,
                location: 'BelowBar', // Top, Bottom
                char: '$',
                size: 'small',
                title: 'Shapes',
            },
            plot_s4: {
                isHidden: false,
                location: 'BelowBar', // Top, Bottom
                char: '$',
                size: 'small',
                title: 'Shapes',
            },
            plot_s5: {
                isHidden: false,
                location: 'BelowBar', // Top, Bottom
                char: '$',
                size: 'small',
                title: 'Shapes',
            },
        },
        is_price_study: true,
        inputs: [
        /*{id: "in_0", name: "in_0", defval: 60, type: "integer", min: 1, max: 1000},*/
        ],
        scriptIdPart: '',
    },
});
export const sg3_light = sg3.duplicate({
    id: 'sg3--light',
    filterOnTheme: 'light',
    metainfo: {
        defaults: {
            styles: {
                plot_0: {
                    color: '#FF0000',
                    textColor: '#FF0000',
                },
                plot_1: {
                    color: '#2196F3',
                    textColor: '#2196F3',
                },
                plot_b1: {
                    color: '#f2c24f',
                    textColor: '#f2c24f',
                },
                plot_b2: {
                    color: '#f2c24f',
                    textColor: '#f2c24f',
                },
                plot_b3: {
                    color: '#EE00FF',
                    textColor: '#EE00FF',
                },
                plot_b4: {
                    color: '#FF0068',
                    textColor: '#FF0068',
                },
                plot_b5: {
                    color: '#f2c24f',
                    textColor: '#f2c24f',
                },
                plot_s1: {
                    color: '#209048',
                    textColor: '#209048',
                },
                plot_s2: {
                    color: '#5ea3ef',
                    textColor: '#5ea3ef',
                },
                plot_s3: {
                    color: '#67ad5b',
                    textColor: '#67ad5b',
                },
                plot_s4: {
                    color: '#209048',
                    textColor: '#209048',
                },
                plot_s5: {
                    color: '#5ea3ef',
                    textColor: '#5ea3ef',
                },
            },
        },
    },
});
