import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
import { useSoundStore } from '~/modules/SDK/sound/useSoundStore';
import dayAPI from '~/utils/dayAPI';
const sounds = useSoundStore.getState();
let exit_sound_flag_ = 0;
export const wu5868_signal5 = createIndicator({
    id: 'wu5868-signal5',
    displayName: '測試',
    constructorScope: {
        init(context, inputCallback) {
            localStorage.setItem('signalInitializedTimestamp', new Date().getTime().toString());
        },
        main(context, inputCallback) {
            const std = this._input(0);
            const itime = this.PineJS.Std.time(this._context);
            const itime_array = this._context.new_var(itime);
            const close = this.ohlc.closeArray;
            const open = this.ohlc.openArray;
            const high = this.ohlc.highArray;
            const low = this.ohlc.lowArray;
            const volume = context.new_var(this.PineJS.Std.volume(context));
            const volume270 = this.PineJS.Std.sma(volume, 270, this._context);
            const stdVolume270 = volume270;
            const interval = context.symbol.period;
            const position = context.new_var();
            const entryPrice = context.new_var();
            const entriesToday = context.new_var();
            const entryTime = this._context.new_var();
            const datetime = dayAPI(this.PineJS.Std.time(context));
            const openTime = datetime.hour() === 9 && datetime.minute() === 0;
            const closeTime = datetime.hour() === 13 && datetime.minute() === 30;
            const lastClose = context.new_var();
            const exitPrice = context.new_var();
            const length = 20;
            const stds = 2;
            const bband = this.bollingerBand(close, length, stds);
            const bbandUp = bband.top;
            const bbandMid = bband.mid;
            const bbandDn = bband.bottom;
            const lowPrice = this._context.new_var();
            const dailyLow = this._context.new_var();
            position.get(2);
            entriesToday.get(5);
            entryPrice.get(5);
            close.get(5);
            open.get(5);
            high.get(5);
            low.get(5);
            volume.get(5);
            exitPrice.get(5);
            itime_array.get(5);
            entryTime.get(5);
            dailyLow.get(5);
            let buy_icon1 = NaN;
            let buy_exit_icon1 = NaN;
            //每日重置紀錄最低點
            if (openTime) {
                dailyLow.set(low.get(0));
            }
            else if (low.get(0) < dailyLow.get(0)) {
                dailyLow.set(low.get(0));
            }
            //每日收盤紀錄當日最低點
            if (closeTime) {
                lowPrice.set(dailyLow.get(0));
            }
            //找出近期5天的最低點
            const lowPricefor5days = this.PineJS.Std.lowest(lowPrice, 6, this._context) * 1.008;
            const longEntry1 = 
            //close.get(0) > bbandDn &&
            low.get(0) < bbandDn && close.get(0) < bbandMid && close.get(0) > bbandDn;
            //high.get(0) < bbandMid &&
            //close.get(0) > open.get(0)
            const longEntry2 = low < lowPricefor5days && close > lowPricefor5days;
            // ---------------------------------------- Entry ----------------------------------------
            if ((interval === '5' && position.get(0) === 0) || isNaN(position.get(0))) {
                if (longEntry1 && longEntry2)
                    position.set(1);
            }
            if (position.get(0) === 1 && position.get(1) !== 1) {
                entryTime.set(itime);
                console.log(datetime.format('YYYY/MM/DD HH:mm'), this.PineJS.Std.lowest(lowPrice, 5, this._context), this.PineJS.Std.lowest(lowPrice, 5, this._context) * 1.008, lowPricefor5days);
                buy_icon1 = 1;
            }
            // ---------------------------------------- Exit ----------------------------------------
            if (position.get(0) > 0 && position.get(1) > 0) {
                //關於當量>270均成交量 設定當根最低價為出場價
                if (volume > stdVolume270) {
                    exitPrice.set(low.get(1));
                }
                if (close < exitPrice.get(0)) {
                    position.set(0);
                    exitPrice.set(NaN);
                    buy_exit_icon1 = 1;
                }
            }
            //聲音------------------------------------------------------------------
            const displayTime = new Date(itime).toLocaleTimeString('en-US');
            const kbarLoadingTimeMs = 10000;
            const isInitialized = new Date().getTime() >
                parseInt(localStorage.getItem('signalInitializedTimestamp') || 'NaN') + kbarLoadingTimeMs;
            const new_bar = this._context.symbol.isNewBar;
            /** 進場部位聲音 */
            if (isInitialized && new_bar) {
                if (position.get(1) === 1 &&
                    position.get(2) !== 1 &&
                    position.get(2) === 0 &&
                    entryTime.get(0) === itime_array.get(1)) {
                    sounds.playSoundWin168LongDebounced();
                    console.log('✅', datetime.format('YYYY/MM/DD'), displayTime, '進多單1');
                }
            }
            //出場部位聲音
            if (isInitialized && new_bar) {
                exit_sound_flag_ = 0;
            }
            const long_exit_condition = buy_exit_icon1 === 1 && exit_sound_flag_ !== 1;
            if (isInitialized) {
                if (long_exit_condition) {
                    sounds.playSoundWin168ExitDebounced();
                    exit_sound_flag_ = 1;
                    console.log('🚨', datetime.format('YYYY/MM/DD'), displayTime, '當沖多單停利或出場訊號');
                }
            }
            return [buy_icon1, buy_exit_icon1, lowPrice.get(0)];
        },
    },
    metainfo: {
        _metainfoVersion: 40,
        scriptIdPart: '',
        is_hidden_study: false,
        is_price_study: true,
        isCustomIndicator: true,
        plots: [
            {
                id: 'entryLongFlag1',
                type: 'chars',
            },
            {
                id: 'exitLongFlag1',
                type: 'chars',
            },
            { id: 'exitPrice', type: 'line' },
        ],
        defaults: {
            styles: {
                entryLongFlag1: { color: '#df484c', textColor: '#df484c', transparency: 20, visible: true },
                exitLongFlag1: { color: '#df484c', textColor: '#df484c', transparency: 20, visible: true },
                exitPrice: {
                    linestyle: 1,
                    linewidth: 2,
                    plottype: 7,
                    transparency: 0,
                    visible: true,
                    color: '#ff8800',
                },
            },
            inputs: {
                std: 2,
            },
        },
        styles: {
            entryLongFlag1: {
                title: '多單進場1',
                isHidden: false,
                location: 'BelowBar',
                char: '▲',
                size: 'small',
                text: '多方拉回',
            },
            exitLongFlag1: {
                title: '多單出場',
                isHidden: false,
                location: 'AboveBar',
                char: 'X',
                size: 'small',
                text: '多單出場',
            },
        },
        inputs: [
            {
                id: 'std',
                name: '成交量倍數',
                defval: 1,
                type: 'float',
                min: 0.1,
                max: 10,
                step: 0.1,
            },
        ],
    },
});
