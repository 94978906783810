import styled from '@emotion/styled';
import { map } from 'lodash';
import { memo, useEffect, useState } from 'react';
import { useAsyncFn, useMount, useUpdateEffect } from 'react-use';
import { useSnapshot } from 'valtio';
import { scrollbar2Css } from '~/css/scrollbarCss';
import { useIntervalNow } from '~/hooks/useIntervalNow';
import { fill_horizontal_all_center, fill_horizontal_cross_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import { useSymbolDictionaryStore } from '~/modules/SDK/Symbol/useSymbolDictionaryStore';
import { useSymbolWatchList } from '~/modules/WatchList/useSymbolWatchList';
import { SignalWallId } from '~/modules/signal-wall/SignalWallId';
import { futuresai_store } from '~/pages/futuresai/_private/futuresai_store';
import dayAPI from '~/utils/dayAPI';
import { useSoundStore } from '~/modules/SDK/sound/useSoundStore';
import { store } from '~/pages/heineken_template/_private/store';
import { css } from '@emotion/react';
import useMedia from '~/hooks/useMedia';
import { DashboardModal, largerTradeStore } from '~/pages/futuresai/large-trade/dashboardModal';
/** 判斷股票目前漲跌狀態 `上漲、下跌、漲停、跌停` */
const stockPriceState = (bs, limit, changePrecent) => {
    if (bs === 'b' && !limit) {
        return 'rises';
    }
    if (bs === 's' && !limit) {
        return 'falls';
    }
    if (bs !== 's' && limit && changePrecent > 0) {
        return 'limitUp';
    }
    if (bs !== 'b' && limit && changePrecent < 0) {
        return 'limitDown';
    }
    return 'unknow';
};
/** 大單的背景顏色 */
const itemBackgroundColor = (bs, limit, changePrecent, stockType) => {
    const condition = stockPriceState(bs, limit, changePrecent);
    const risesColor = stockType ? '#f4b1b8' : '#f9c3e2';
    const fallsColor = stockType ? '#c8e9d0' : '#c8e3e9';
    if (condition === 'rises') {
        return risesColor;
    }
    else if (condition === 'falls') {
        return fallsColor;
    }
    else if (condition === 'limitUp') {
        return risesColor;
    }
    else if (condition === 'limitDown') {
        return fallsColor;
    }
    else
        return 'transparent';
};
/** 大單的買進賣出顏色 */
const itemQtyBSColor = (bs, limit, changePrecent) => {
    const condition = stockPriceState(bs, limit, changePrecent);
    if (condition === 'rises') {
        return '#ff2222';
    }
    else if (condition === 'falls') {
        return '#00aa00';
    }
    else if (condition === 'limitUp') {
        return '#ff2222';
    }
    else if (condition === 'limitDown') {
        return '#ff2222';
    }
    else
        return '#252525';
};
/** 漲跌的顏色 依照漲跌幅做變化 */
const changeColor = (closeChange) => {
    if (closeChange > 0) {
        return '#ff2222';
    }
    else if (closeChange < 0) {
        return '#00aa00';
    }
    else
        return '#252525';
};
const closeTextColor = (change, limit) => {
    if (limit === true) {
        return '#ffffff';
    }
    else if (change > 0) {
        return '#ff2222';
    }
    else if (change < 0) {
        return '#00aa00';
    }
    else
        return '#252525';
};
const closeBackgroundColor = (change, limit) => {
    if (limit === false) {
        return 'transparent';
    }
    else if (change > 0) {
        return '#ff2222';
    }
    else if (change < 0) {
        return '#00aa00';
    }
    else
        return 'transparent';
};
export const SignalWall = memo(function SignalWall() {
    const { isPc } = useMedia();
    const sounds = useSoundStore.getState();
    const state = useSnapshot(largerTradeStore);
    const signalsStore = futuresai_store.signalWallPage.signals;
    const signalsState = useSnapshot(signalsStore.store);
    const dictionary = useSymbolDictionaryStore(s => s.dictionary);
    const [data, fetch] = useAsyncFn(async () => {
        return signalsStore.fetchAndUpdate();
    }, []);
    useMount(() => {
        signalsStore.toggle({
            query: SignalWallId.盤中突破日內高點,
            displayName: '盤中突破日內高點',
        }, false);
        signalsStore.toggle({
            query: SignalWallId.盤中突破日內低點,
            displayName: '盤中突破日內低點',
        }, false);
        signalsStore.toggle({
            query: SignalWallId.盤中高預估量比,
            displayName: '盤中高預估量比',
        }, false);
        signalsStore.toggle({
            query: SignalWallId.盤中創五日新高,
            displayName: '盤中創五日新高',
        }, false);
        signalsStore.toggle({
            query: SignalWallId.盤中創五日新低,
            displayName: '盤中創五日新低',
        }, false);
        signalsStore.toggle({
            query: SignalWallId.盤中高預估周轉率,
            displayName: '盤中高預估周轉率',
        }, false);
        signalsStore.toggle({
            query: SignalWallId.大單成交,
            displayName: '大單成交',
        }, false);
    });
    useIntervalNow(() => {
        fetch();
    }, 1000 * 3);
    useUpdateEffect(() => {
        fetch();
    }, [signalsState.from, signalsState.to, signalsState.sorter]);
    useUpdateEffect(() => {
        signalsStore.update();
    }, [
        signalsState.filter.text,
        signalsState.filter.minVolume,
        signalsState.filter.maxVolume,
        signalsState.filter.minAmount,
        signalsState.filter.maxAmount,
        signalsState.filter.stockFuture,
    ]);
    const adata = signalsState.data.filter(s => s.signal_id === 'qty_alert2');
    const [temporaryData, setTemporaryData] = useState(['']);
    const filterSymbolGroupName = 'signal_wall_fliter';
    const filterSymbolsState = useSymbolWatchList(filterSymbolGroupName);
    useEffect(() => {
        setTemporaryData(temporaryData);
        sounds.playSoundNotifyDebounced();
    }, [JSON.stringify(adata), sounds, temporaryData]);
    return (<classes.container>
      <classes.dashboard.container>
        <DashboardModal />
      </classes.dashboard.container>

      <classes.signalWall.container>
        <classes.signalWall.header>
          <span>時間</span>
          <span>商品</span>
          <span>代號</span>
          <span>方向</span>
          <span>單量</span>
          <span>價格</span>
          <span>幅度</span>
          <span>金額</span>
        </classes.signalWall.header>

        <classes.signalWall.body css={css `
            font-size: ${isPc ? '13px' : '11px'};
          `}>
          {map(adata, (datum, key) => {
            /** 商品名稱 */
            const symbolName = dictionary[datum.symbol.replace('-2', '-1')];
            /** 判斷是否股期 包含`-符號與字母不為T開頭` */
            const isStockFutures = datum.symbol.charAt(0) !== 'T' && datum.symbol.includes('-');
            /** 標的單位 */
            const unit = !isStockFutures ? '張' : '口';
            /** 單量 */
            const tickQty = datum.signal_data && 'qty' in datum.signal_data ? datum.signal_data['qty'] : 0;
            /** 最新成交價 */
            const close = datum.signal_data && 'close' in datum.signal_data ? datum.signal_data['close'] : 0;
            /** 漲跌停 是否為漲跌停 */
            const limitUp = datum.signal_data && 'limitUpPrice' in datum.signal_data
                ? datum.signal_data['limitUpPrice']
                : 0;
            const limitDn = datum.signal_data && 'limitDownPrice' in datum.signal_data
                ? datum.signal_data['limitDownPrice']
                : 0;
            const isLimit = close === limitUp || close === limitDn;
            /** 昨收 */
            const prevref = datum.signal_data && 'prevRef' in datum.signal_data ? datum.signal_data['prevRef'] : 0;
            /** 成交金額 單:位萬 */
            const tradeValue = tickQty * close * 0.1 * (isStockFutures ? 2 : 1);
            /** 變動 */
            const changePrecent = ((close - prevref) / prevref) * 100;
            /** 變動符號 */
            const changeSymbol = changePrecent > 0 ? '+' : '';
            /** 買進/賣出 */
            const tickBS = datum.signal_data && 'bs' in datum.signal_data ? datum.signal_data['bs'] : 'n';
            const ticlType = tickBS === 'b'
                ? '買進'
                : tickBS === 's'
                    ? '賣出'
                    : tickBS === 'p'
                        ? '盤前'
                        : changePrecent > 0 && tickBS === 'n'
                            ? '賣出'
                            : changePrecent < 0 && tickBS === 'n'
                                ? '買進'
                                : '';
            /** 篩選:單量 股期等同金額只要大於500萬就好 現貨要大於500萬與單量20張以上 */
            const filterBaseCondition = !isStockFutures
                ? tradeValue >= state.filterTickValue && tickQty >= state.filterTickQty
                : tradeValue >= state.filterTickValue;
            /** 篩選:方向 */
            const filterBSCondition = state.filterBS !== 'all' ? tickBS === state.filterBS : true;
            /** 篩選:自訂篩選商品 */
            const filterWatchListSymbols = state.filterSymbols
                ? filterSymbolsState.state.symbolListArray.includes(datum.symbol)
                : true;
            /** 篩選:金融股 */
            const excludeFinancialSymbols = state.excludeFinancial
                ? !/^28.+$/.test(datum.symbol)
                : true;
            /** 篩選: 股期 */
            const excludeStock = state.excludeFutures ? !datum.symbol.includes('-') : true;
            /** 篩選:ETF */
            const excludeETFSymbols = state.excludeETF ? !/^00.+$/.test(datum.symbol) : true;
            /** 本機當下時間 */
            const currentTime = dayAPI();
            /** 大單資料時間 */
            const dataTime = dayAPI(datum.datetime);
            /** 最新30秒的大單資料 */
            const diffTime = currentTime.diff(dataTime, 'seconds') < 10;
            if (filterBaseCondition &&
                filterBSCondition &&
                filterWatchListSymbols &&
                excludeFinancialSymbols &&
                excludeETFSymbols &&
                excludeStock) {
                return (<classes.signalWall.message key={key} backgroundColor={itemBackgroundColor(tickBS, isLimit, changePrecent, !isStockFutures)} newMessage={diffTime} onClick={() => store.charting.changeSymbol(datum.symbol)}>
                  <classes.signalWall.item>
                    {dayAPI(datum.datetime).format('HH:mm:ss')}
                  </classes.signalWall.item>
                  <classes.signalWall.item>{symbolName}</classes.signalWall.item>
                  <classes.signalWall.item>{datum.symbol}</classes.signalWall.item>
                  <classes.signalWall.item>
                    <classes.signalWall.type backgroundColor={itemQtyBSColor(tickBS, isLimit, changePrecent)}>
                      {ticlType}
                    </classes.signalWall.type>
                  </classes.signalWall.item>
                  <classes.signalWall.item>
                    {tickQty}
                    {unit}
                  </classes.signalWall.item>
                  <classes.signalWall.item>
                    <classes.signalWall.close color={closeTextColor(changePrecent, isLimit)} backgroundColor={closeBackgroundColor(changePrecent, isLimit)}>
                      {close?.toFixed(2)}
                    </classes.signalWall.close>
                  </classes.signalWall.item>
                  <classes.signalWall.item>
                    <classes.signalWall.price color={changeColor(changePrecent)}>
                      {changeSymbol}
                      {changePrecent.toFixed(2)}%
                    </classes.signalWall.price>
                  </classes.signalWall.item>
                  <classes.signalWall.item>{tradeValue.toFixed(0)}萬</classes.signalWall.item>
                </classes.signalWall.message>);
            }
        })}
        </classes.signalWall.body>
      </classes.signalWall.container>
    </classes.container>);
});
const classes = {
    container: styled.div `
    ${fill_vertical_cross_center};
    padding: 4px;
  `,
    signalWall: {
        container: styled.div `
      ${fill_vertical_cross_center};
      height: calc(100% - 30px);
      font-size: 13px;
      transition: 0.3s;
    `,
        header: styled.div `
      ${fill_horizontal_cross_center};
      display: grid;
      grid-template-columns: 12% 12% 12% 12% 12% 12% 12% 12%;
      height: 32px;
      background-color: #495a7e;
      color: #ffffff;
      font-weight: 500;
      border-radius: 4px 4px 0 0;
      & > * {
        ${fill_horizontal_all_center};
      }
    `,
        body: styled.div `
      ${fill_vertical_cross_center};
      ${scrollbar2Css};
      height: calc(100% - 32px);
      gap: 1px;
    `,
        message: styled.div `
      background-color: ${props => !props.newMessage ? props.backgroundColor + '58' : props.backgroundColor + 'aa'};
      ${fill_horizontal_cross_center};
      display: grid;
      grid-template-columns: 12% 12% 12% 12% 12% 12% 12% 12%;
      width: 100%;
      height: 32px;
      flex-shrink: 0;
      border-radius: 2px;
      cursor: pointer;
      & > * {
        ${fill_horizontal_all_center};
        white-space: nowrap;
      }
    `,
        item: styled.div ``,
        price: styled.div `
      color: ${props => props.color};
    `,
        close: styled.div `
      padding: 2px 4px;
      border-radius: 4px;
      color: ${props => props.color};
      background-color: ${props => props.backgroundColor};
    `,
        type: styled.div `
      color: ${props => props.backgroundColor};
      font-weight: 600;
    `,
    },
    dashboard: {
        container: styled.div `
      ${fill_horizontal_cross_center};
      width: 100%;
      height: 30px;
      font-size: 12px;
    `,
    },
};
