import { proxy } from 'valtio';
import { FuiCollapse } from '~/pages/heineken_template/components/FuiCollapse';
import { FuiTabs2 } from '~/pages/heineken_template/components/FuiTabs2';
export const good178S_store = proxy({
    /** 作用於指標，標記指標應採用哪一種策略方向 */
    usedStrategyTrend: 'default',
    tabsOfMain: new FuiTabs2(['選股', '當日訊號', '自選股', '60分K訊號', '突破']),
    tabOfToday: new FuiTabs2(['當日多方訊號', '當日空方訊號', '當日突破多訊號', '當日突破空訊號']),
    tabOf60k: new FuiTabs2(['一小時多方訊號', '一小時空方訊號']),
    holdingPricesCollapse: new FuiCollapse({ open: true }),
    trend: [],
});
export const resetIndicatorsValue = () => {
    good178S_store.trend = [];
};
