import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
export const big3_stockTrade = createIndicator({
    displayName: '訊號',
    id: 'big3-stockTrade',
    constructorScope: {
        init(context, inputCallback) {
            const ticker = this.PineJS.Std.ticker(this._context)
                .toString()
                .replace(/[^\w-]/g, '');
            const symbol = ticker + '#bs_indicator_acc';
            this._context.new_sym(symbol, this.PineJS.Std.period(this._context));
        },
        main(context, inputCallback) {
            const i = context.new_unlimited_var(this._context.symbol.time);
            this._context.select_sym(1);
            const time1 = this._context.new_unlimited_var(this._context.symbol.time);
            const chip = this.PineJS.Std.high(this._context);
            const unlimited1 = this._context.new_unlimited_var(chip);
            const value1 = unlimited1.adopt(time1, i, 0); // <=這是籌碼
            //主圖商品
            this._context.select_sym(0);
            this.PineJS.Std.period(this._context);
            const marketposition = this._context.new_var();
            const exitFlag = this._context.new_var();
            const entryPrice = this._context.new_var();
            const close = this.PineJS.Std.close(this._context);
            const open = this.PineJS.Std.open(this._context);
            const low = this.PineJS.Std.low(this._context);
            const high = this.PineJS.Std.high(this._context);
            const stopLossPrice = this._context.new_var();
            const k = this.analyzer(18, 6, 6)[0];
            const d = this.analyzer(18, 6, 6)[1];
            const dc_dn = this.ladderChannl(20)[0];
            const dc_up = this.ladderChannl(20)[1];
            const midPrice = this.ladderMidPrice(5);
            const stopLossPriceLong = low < midPrice.get(0) ? low : midPrice.get(0);
            const stopLossPriceShort = high > midPrice.get(0) ? high : midPrice.get(0);
            marketposition.get(2);
            entryPrice.get(2);
            stopLossPrice.get(2);
            const long = close > midPrice.get(0) && isNaN(dc_up) && k > d && close > open;
            const short = close < midPrice.get(0) && isNaN(dc_dn) && k < d && close < open;
            const time = this.PineJS.Std.time(this._context);
            let buy_icon = NaN;
            let short_icon = NaN;
            let buy_target_icon = NaN;
            let short_target_icon = NaN;
            if (long) {
                marketposition.set(1);
            }
            if (short) {
                marketposition.set(-1);
            }
            if (marketposition.get(0) === 1 && marketposition.get(1) !== 1) {
                buy_icon = 1;
                entryPrice.set(close);
                stopLossPrice.set(stopLossPriceLong);
                exitFlag.set(0);
            }
            if (marketposition.get(0) === -1 && marketposition.get(1) !== -1) {
                short_icon = 1;
                entryPrice.set(close);
                stopLossPrice.set(stopLossPriceShort);
                exitFlag.set(0);
            }
            const sar_long = this.sarLongStopLoss('linear', 9, marketposition, 10, 1.2, 0.12, 4);
            const sar_short = this.sarShortStopLoss('linear', 9, marketposition, 10, 1.2, 0.22, 4);
            if (marketposition.get(0) === 1 && marketposition.get(1) === 1) {
                if (close < stopLossPrice.get(0)) {
                    entryPrice.set(NaN);
                    marketposition.set(0);
                }
                if (exitFlag.get(0) === 0 && high > entryPrice.get(0) * 1.1) {
                    buy_target_icon = 1;
                    exitFlag.set(1);
                }
                if (exitFlag.get(0) === 1 && high > entryPrice.get(0) * 1.3) {
                    buy_target_icon = 1;
                    exitFlag.set(2);
                }
                if (exitFlag.get(0) === 2 && high > entryPrice.get(0) * 1.5) {
                    buy_target_icon = 1;
                    exitFlag.set(3);
                    entryPrice.set(NaN);
                    marketposition.set(0);
                }
                if (close < sar_long) {
                    entryPrice.set(NaN);
                    marketposition.set(0);
                }
            }
            if (marketposition.get(0) === -1 && marketposition.get(1) === -1) {
                if (close > stopLossPrice.get(0)) {
                    entryPrice.set(NaN);
                    marketposition.set(0);
                }
                if (exitFlag.get(0) === 0 && low < entryPrice.get(0) * 0.9) {
                    short_target_icon = 1;
                    exitFlag.set(1);
                }
                if (exitFlag.get(0) === 1 && low < entryPrice.get(0) * 0.7) {
                    short_target_icon = 1;
                    exitFlag.set(2);
                }
                if (exitFlag.get(0) === 2 && low < entryPrice.get(0) * 0.5) {
                    short_target_icon = 1;
                    exitFlag.set(3);
                    entryPrice.set(NaN);
                    marketposition.set(0);
                }
                if (close > sar_short) {
                    entryPrice.set(NaN);
                    marketposition.set(0);
                }
            }
            //sar線--------------------------------------------------
            const sarLongLine = this._context.new_var();
            const sarShortLine = this._context.new_var();
            if (marketposition.get(0) === 1) {
                sarLongLine.set(sar_long.get(0));
                sarShortLine.set(NaN);
            }
            if (marketposition.get(0) === -1) {
                sarLongLine.set(NaN);
                sarShortLine.set(sar_short.get(0));
            }
            if (marketposition.get(0) === 0) {
                sarLongLine.set(NaN);
                sarShortLine.set(NaN);
            }
            const colorer = () => {
                return marketposition.get(0) === 1 ? 0 : marketposition.get(0) === -1 ? 1 : 2;
            };
            return [
                NaN,
                buy_icon,
                short_icon,
                buy_target_icon,
                short_target_icon,
                sarLongLine.get(0),
                sarShortLine.get(0),
                colorer(),
            ];
        },
    },
    metainfo: {
        _metainfoVersion: 40,
        scriptIdPart: '',
        is_hidden_study: false,
        is_price_study: true,
        isCustomIndicator: true,
        plots: [
            { id: 'plot_0', type: 'line' },
            {
                id: 'plot_1',
                type: 'chars',
            },
            {
                id: 'plot_2',
                type: 'chars',
            },
            {
                id: 'plot_3',
                type: 'chars',
            },
            {
                id: 'plot_4',
                type: 'chars',
            },
            {
                id: 'plot_5',
                type: 'line',
            },
            {
                id: 'plot_6',
                type: 'line',
            },
            {
                id: '多空顏色',
                type: 'bar_colorer',
                palette: 'barPalette',
            },
        ],
        defaults: {
            styles: {
                plot_0: {
                    linestyle: 1,
                    visible: true,
                    linewidth: 1,
                    plottype: 0,
                    trackPrice: false,
                    transparency: 30,
                    color: '#5577ff',
                },
                plot_1: {
                    color: '#dd5e56',
                    textColor: '#dd5e56',
                    transparency: 0,
                    visible: true,
                },
                plot_2: {
                    color: '#6ab6ec',
                    textColor: '#6ab6ec',
                    transparency: 0,
                    visible: true,
                },
                plot_3: {
                    color: '#dd5e56',
                    textColor: '#dd5e56',
                    transparency: 0,
                    visible: true,
                },
                plot_4: {
                    color: '#6ab6ec',
                    textColor: '#6ab6ec',
                    transparency: 0,
                    visible: true,
                },
                plot_5: {
                    color: '#dd5e56',
                    textColor: '#dd5e56',
                    transparency: 30,
                    visible: true,
                    linestyle: 2,
                    linewidth: 1,
                },
                plot_6: {
                    color: '#52a49a',
                    textColor: '#52a49a',
                    transparency: 30,
                    visible: true,
                    linestyle: 2,
                    linewidth: 1,
                },
            },
        },
        styles: {
            plot_0: {
                title: '日成本線',
                histogramBase: 0,
            },
            plot_1: {
                isHidden: false,
                location: 'BelowBar',
                char: '▲',
                size: 'small',
                text: '多單',
                title: '多單參考訊號',
            },
            plot_2: {
                isHidden: false,
                location: 'AboveBar',
                char: '▼',
                size: 'small',
                text: '空單',
                title: '空單參考訊號',
            },
            plot_3: {
                isHidden: false,
                location: 'AboveBar',
                char: '✪',
                size: 'tiny',
                text: '',
                title: '多單參考停利訊號',
            },
            plot_4: {
                isHidden: false,
                location: 'BelowBar',
                char: '✪',
                size: 'tiny',
                text: '',
                title: '空單參考停利訊號',
            },
            plot_5: {
                isHidden: false,
                title: '多單出場參考線',
            },
            plot_6: {
                isHidden: false,
                title: '空單出場參考線',
            },
        },
        palettes: {
            barPalette: {
                colors: {
                    0: {
                        color: '#df484c',
                        width: 1,
                        style: 0,
                    },
                    1: {
                        color: '#5cb642',
                        width: 1,
                        style: 0,
                    },
                    2: {
                        color: '#b2b5be',
                        width: 1,
                        style: 0,
                    },
                },
            },
        },
        inputs: [],
    },
});
