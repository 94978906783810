import { css } from '@emotion/react';
import { memo } from 'react';
import useMedia from '~/hooks/useMedia';
import { fill_horizontal_cross_center, jc } from '~/modules/AppLayout/FlexGridCss';
import { ChartingServerSwitch } from '~/modules/SDK/chart4/components/ChartingServerSwitch';
import { Preset_Topbar } from '~/pages/heineken_template/_preset/preset_Topbar';
import { store } from '~/pages/heineken_template/_private/store';
import styled from '@emotion/styled';
export const Freeman_TopBar = memo(function Freeman_TopBar() {
    const { isPc } = useMedia();
    return (<styleds.container>
      {isPc && (<div css={css `
            color: #353535;
            font-size: 22px;
            font-weight: 600;
          `}>
          自由人看盤介面
        </div>)}
      {isPc && (<styleds.desktopComponent>
          <ChartingServerSwitch charting={store.charting}/>
          {/* <edwin_store.userAvatar.AvatarModal
              cssset={css`
                width: 280px;
                height: 360px;
                transition: 0.3s;
              `}
            >
              <EdwinLogOutView />
            </edwin_store.userAvatar.AvatarModal> */}
        </styleds.desktopComponent>)}
      {!isPc && (<Preset_Topbar showLeftBurger showRightBurger componentsInRight={<div css={css `
                ${fill_horizontal_cross_center};
                justify-content: end;
              `}>
              <ChartingServerSwitch charting={store.charting}/>
              {/* <edwin_store.userAvatar.AvatarModal
                  cssset={css`
                    width: 280px;
                    height: 360px;
                    transition: 0.3s;
                  `}
                >
                  <EdwinLogOutView />
                </edwin_store.userAvatar.AvatarModal> */}
            </div>}/>)}
    </styleds.container>);
});
//LogoutView
const styleds = {
    container: styled.div `
    ${fill_horizontal_cross_center};
    ${jc.spaceBetween};
    padding: 0 6px;
  `,
    desktopTop: styled.div `
    ${fill_horizontal_cross_center};
    justify-content: end;
  `,
    desktopComponent: styled.div `
    ${fill_horizontal_cross_center};
    width: 240px;
    justify-content: end;
    gap: 4px;
  `,
};
