import { css } from '@emotion/react';
import { memo } from 'react';
import { fill_vertical_cross_center } from '~/modules/AppLayout/FlexGridCss';
import { apirc } from '~/configs/apirc';
import useSWR from 'swr-0-5-6';
import urlcat from 'urlcat';
import dayAPI from '~/utils/dayAPI';
//todo:
//近日振幅
//近月(sm)振幅
//symbol=TXAM-1&resolution=D 20天
//月合約: 開 高(發生日) 低(發生日) 月振幅(current) 月成本(current)
//週合約: 開 高(發生日) 低(發生日) 月振幅(current) 月成本(current)
//處理 合約代號邏輯(opkevin)
//關卡價格(多)
//關卡價格(空)
//須知道 當下合約的高低開 成本
//100%
//75%
//50%
//25%
const ROOT_URL = apirc.tvAPIsOfGCP;
const emptyData = {
    t: [0],
    o: [0],
    h: [0],
    l: [0],
    c: [0],
    v: [0],
    s: 'ok',
};
const errorEmptyArrayFetcher = async (url) => {
    const res = await fetch(url);
    return !res.ok ? [] : res.json();
};
export const useGetDataInfo = (options) => {
    const { symbol, resolution, from } = options;
    const url = urlcat(`${ROOT_URL}/history`, { symbol, resolution, from });
    const res = useSWR(url, errorEmptyArrayFetcher, {
        shouldRetryOnError: false,
        revalidateOnFocus: false,
    });
    return { data: res.data };
};
const dailyAmplitudeCalculator = (data, numDays) => {
    const dailyAmplitudes = [];
    for (let i = data.t.length - 1; i >= Math.max(data.t.length - numDays, 0); i--) {
        const amplitude = data.h[i] - data.l[i];
        dailyAmplitudes.unshift(amplitude);
    }
    const sum = dailyAmplitudes.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    return sum / dailyAmplitudes.length;
};
export const Freeman_SidePane2 = memo(function Freeman_SidePane2() {
    const symbol = 'TXAM-1';
    const fromMounth = dayAPI().subtract(20, 'month').unix();
    const fromWeek = dayAPI().subtract(20, 'week').unix();
    const fromDay = dayAPI().subtract(60, 'day').unix();
    const dataOfMonths = useGetDataInfo({ symbol, resolution: 'SM', from: fromMounth }).data ?? emptyData;
    const dataOfWeeks = useGetDataInfo({ symbol, resolution: 'SW', from: fromWeek }).data ?? emptyData;
    const dataOfDays = useGetDataInfo({ symbol, resolution: 'D', from: fromDay }).data ?? emptyData;
    const amp5Days = dailyAmplitudeCalculator(dataOfDays, 5);
    const amp10Days = dailyAmplitudeCalculator(dataOfDays, 10);
    const amp20Days = dailyAmplitudeCalculator(dataOfDays, 20);
    const amp5Weeks = dailyAmplitudeCalculator(dataOfWeeks, 5);
    const amp10Weeks = dailyAmplitudeCalculator(dataOfWeeks, 10);
    const amp20Weeks = dailyAmplitudeCalculator(dataOfWeeks, 20);
    const dataOfWeekLenght = dataOfWeeks.t.length;
    const amp5Months = dailyAmplitudeCalculator(dataOfMonths, 5);
    const amp10Months = dailyAmplitudeCalculator(dataOfMonths, 10);
    const amp20Months = dailyAmplitudeCalculator(dataOfMonths, 20);
    const dataOfMonthLenght = dataOfMonths.t.length;
    const weekCost = (dataOfWeeks.h[dataOfWeekLenght - 1] + dataOfWeeks.l[dataOfWeekLenght - 1]) / 2;
    const monthCost = (dataOfMonths.h[dataOfMonthLenght - 1] + dataOfMonths.l[dataOfMonthLenght - 1]) / 2;
    //月合約價格資訊
    const contractHigh = dataOfMonths?.h[dataOfMonthLenght - 1];
    const contractLow = dataOfMonths?.l[dataOfMonthLenght - 1];
    const contractOpen = dataOfMonths?.o[dataOfMonthLenght - 1];
    return (<div css={classes.container}>
      <span>
        換倉日:{dataOfMonths?.t.map(s => dayAPI(Number(s) * 1000).format('YYYY/MM/DD')).slice(-1)}
      </span>
      <span>合約開:{contractOpen}</span>
      <span>合約高:{contractHigh}</span>
      <span>合約低:{contractLow}</span>
      <span>月成本:{monthCost.toFixed(0)}</span>

      <span>-</span>

      <span>近5月振幅:{amp5Months.toFixed(0)}</span>
      <span>近10月振幅:{amp10Months.toFixed(0)}</span>
      <span>近20月振幅:{amp20Months.toFixed(0)}</span>

      <span>-</span>
      <span>近5週振幅:{amp5Weeks.toFixed(0)}</span>
      <span>近10週振幅:{amp10Weeks.toFixed(0)}</span>
      <span>近20週振幅:{amp20Weeks.toFixed(0)}</span>
      {/* <span>週成本:{weekCost.toFixed(0)}</span> */}

      <span>-</span>

      <span>近5日振幅:{amp5Days.toFixed(0)}</span>
      <span>近10日振幅:{amp10Days.toFixed(0)}</span>
      <span>近20日振幅:{amp20Days.toFixed(0)}</span>

      <span>-</span>
      <span>多</span>
      <span>關卡價1(+500): {contractLow + 500}</span>
      <span>關卡價2(+700): {contractLow + 700}</span>
      <span>關卡價3(+1000): {contractLow + 1000}</span>

      <span>-</span>
      <span>空</span>
      <span>關卡價1(-500): {contractHigh - 500}</span>
      <span>關卡價2(-700): {contractHigh - 700}</span>
      <span>關卡價3(-1000): {contractHigh - 1000}</span>
    </div>);
});
const classes = {
    container: css `
    ${fill_vertical_cross_center};
    padding: 4px;
    gap: 4px;
    background-color: #151515;
    border-radius: 4px;
    user-select: none;
  `,
};
