import { css } from '@emotion/react';
import { memo } from 'react';
import IntradayTrendChart from '~/modules/trendChart';
import TitleSymbolQuote from '~/modules/investment-consultant/symbolList/body/SymbolQuoteTitle';
export const FreemanSymbolChart = memo(function FreemanSymbolChart(props) {
    return (<div css={css `
        display: grid;
        grid-template-rows: 26px 1fr;
        width: 100%;
        height: 100%;
        flex-shrink: 0;
        background-color: #1c1c1c;
        border-radius: 4px;
      `}>
      <div css={css `
          & > div {
            width: 100%;
            height: 26px;
            font-size: 12px;
          }
        `}>
        <TitleSymbolQuote.Simple symbol={props.symbol}/>
      </div>

      <div css={css `
          position: relative;
          width: 100%;
          height: 116px;
        `}>
        <div css={css `
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
          `}>
          <IntradayTrendChart symbol={props.symbol} ticksSize={11} ticksHeight={20} priceTicksMargin={-14} priceTicksSize={11}/>
        </div>
      </div>
    </div>);
});
