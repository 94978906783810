import { css } from '@emotion/react';
import { useThemeStore } from '~/components/theme/useThemeStore';
import { createIPadCss } from '~/css/createIPadCss';
import { createMobileCss2 } from '~/css/createMobileCss';
import { meCheckHandlerCreateByProductNamesWithExpiredAt } from '~/modules/SDK/me/meCheckHandlerCreateByProductName';
import { store } from '~/pages/heineken_template/_private/store';
import { AsiajyeStock_Sidebar } from './asiajyeStock_Sidebar';
import { asiajyeStock_initStrategies } from './asiajyeStock_initStrategies';
import { AsiajyeStock_Topbar } from './asiajyeStock_Topbar';
import { fr_me } from '~/pages/heineken_template/_fr/fr_me';
import { useSignalrStart2_0 } from '~/modules/SDK/Signalr/useSignalrStart2_0';
import { BsChevronLeft } from 'react-icons/bs';
import Fr_WithDrawerCloseButton from '~/pages/heineken_template/_fr/fr_WithDrawerCloseButton';
export const asiajyeStock_init = (templateProps) => {
    templateProps.permissions.pageview = meCheckHandlerCreateByProductNamesWithExpiredAt([
        'web_stock',
        'web_stock_pro',
    ]);
    asiajyeStock_initStrategies();
    useThemeStore.setState({ theme: 'light' });
    store.charting.lightOverrides = {
        'mainSeriesProperties.candleStyle.borderDownColor': '#6ba583',
        'mainSeriesProperties.candleStyle.borderUpColor': '#d75442',
        'mainSeriesProperties.candleStyle.downColor': '#6ba583',
        'mainSeriesProperties.candleStyle.upColor': '#d75442',
        'mainSeriesProperties.candleStyle.wickDownColor': '#555555',
        'mainSeriesProperties.candleStyle.wickUpColor': '#555555',
        'mainSeriesProperties.candleStyle.drawBorder': false,
        'mainSeriesProperties.showPriceLine': false,
        'mainSeriesProperties.showCountdown': true,
        'paneProperties.horzGridProperties.style': 1,
        'paneProperties.vertGridProperties.style': 1,
        'paneProperties.topMargin': 15,
        'paneProperties.bottomMargin': 15,
        'paneProperties.vertGridProperties.color': '#cccccc',
        'paneProperties.horzGridProperties.color': '#cccccc',
        'paneProperties.background': '#ffffff',
        'paneProperties.legendProperties.showStudyValues': true,
        'timeScale.rightOffset': 0,
        'scalesProperties.fontSize': 14,
        'scalesProperties.textColor': '#333333',
        'scalesProperties.lineColor': '#999999',
    };
    store.charting.widgetOptions = {
        ...store.charting.widgetOptions,
        disabledLeftToolbar: true,
        enableVolumeIndicator: true,
        overrides: store.charting.lightOverrides,
    };
    templateProps.layout.cssset = css `
    grid-template-rows: 48px 1fr;
    grid-template-columns: 336px 1fr;
    place-content: flex-start;

    grid-template-areas:
      'Drawer1 Row1'
      'Drawer1 Chart';

    ${createIPadCss(css `
      grid-template-rows: 48px 1fr;
      grid-template-columns: 1fr;
      grid-template-areas:
        'Row1 Row1'
        'Chart Chart';
    `)}

    ${createMobileCss2(css `
      grid-template-rows: 48px 1fr;
      grid-template-columns: 1fr;
      grid-template-areas:
        'Row1 Row1'
        'Chart Chart';
    `)}
  `;
    templateProps.layout.Row1 = AsiajyeStock_Topbar;
    templateProps.layout.Drawer1 = (<Fr_WithDrawerCloseButton.Display right Button={<BsChevronLeft css={css `
            #__html {
              position: relative;
              z-index: 100;
            }
            color: #ffffff;
            background-color: #3c60cb66;
            border-radius: 5px;
            height: 64px;
            width: 28px;
            font-size: 24px;
          `}/>}>
      <AsiajyeStock_Sidebar />
    </Fr_WithDrawerCloseButton.Display>);
    templateProps.hooks.add(fr_me.useInstall);
    templateProps.hooks.add(useSignalrStart2_0);
};
