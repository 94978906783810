import styled from '@emotion/styled';
import { memo } from 'react';
import { fill_horizontal_all_center, fill_horizontal_cross_center, } from '~/modules/AppLayout/FlexGridCss';
//目的: 用戶可以快速選到股票
//商品類別
const screener1_1 = ['有股期', '有可轉債', '上市', '上櫃', '興櫃'];
const screener2_1 = ['小型股本', '中小型股本', '中大型股本', '大型股本'];
const screener2_2 = ['本益比<10', '本益比<20', '本益比<30', '本益比>30', '本益比>60'];
const screener3_1 = ['10月月增率>100%', '10月月增率>500%', '10月年增率>100%', '10月年增率>500%'];
const screener3_2 = ['11月月增率>100%', '11月月增率>500%', '11月年增率>100%', '11月年增率>500%'];
const screener3_3 = ['23Q2轉虧為盈', '23Q3轉虧為盈'];
const screener4_1 = [
    '20日區間高點',
    '60日區間高點',
    '120日區間高點',
    '20日區間低點',
    '60日區間低點',
    '120日區間低點',
];
const screener4_2 = ['20日新高', '60日新高', '120日新高', '20日新低', '60日新低', '120日新低'];
const screener5_1 = ['爆量', '量縮'];
const screener6_1 = [
    '近3週大戶比例增加',
    '近6週大戶比例增加',
    '近3週大戶比例減少',
    '近6週大戶比例減少',
];
const screener6_2 = ['外資5日買超', '自營5日買超', '投信5日買超', '合計5日買超'];
const screener6_3 = ['外資5日賣超', '自營5日賣超', '投信5日賣超', '合計5日賣超'];
export const ScreenerBase = memo(function ScreenerBase() {
    return (<classes.container>
      <classes.board.container>
        <classes.board.mainTitle>標的類別</classes.board.mainTitle>
        <classes.board.selecteContent>
          {screener1_1.map(s => {
            return <classes.board.selectItem key={s}>{s}</classes.board.selectItem>;
        })}
        </classes.board.selecteContent>

        <classes.board.mainTitle>基本選股</classes.board.mainTitle>
        <classes.board.subTitle>股本</classes.board.subTitle>
        <classes.board.selecteContent>
          {screener2_1.map(s => {
            return <classes.board.selectItem key={s}>{s}</classes.board.selectItem>;
        })}
        </classes.board.selecteContent>
        <classes.board.subTitle>本益比</classes.board.subTitle>
        <classes.board.selecteContent>
          {screener2_2.map(s => {
            return <classes.board.selectItem key={s}>{s}</classes.board.selectItem>;
        })}
        </classes.board.selecteContent>

        <classes.board.mainTitle>營收狀況</classes.board.mainTitle>
        <classes.board.subTitle>最新月份</classes.board.subTitle>
        <classes.board.selecteContent>
          {screener3_1.map(s => {
            return <classes.board.selectItem key={s}>{s}</classes.board.selectItem>;
        })}
        </classes.board.selecteContent>
        <classes.board.subTitle>次月月份</classes.board.subTitle>
        <classes.board.selecteContent>
          {screener3_2.map(s => {
            return <classes.board.selectItem key={s}>{s}</classes.board.selectItem>;
        })}
        </classes.board.selecteContent>
        <classes.board.subTitle>季營收</classes.board.subTitle>
        <classes.board.selecteContent>
          {screener3_3.map(s => {
            return <classes.board.selectItem key={s}>{s}</classes.board.selectItem>;
        })}
        </classes.board.selecteContent>

        <classes.board.mainTitle>價格狀況</classes.board.mainTitle>
        <classes.board.subTitle>區間</classes.board.subTitle>
        <classes.board.selecteContent>
          {screener4_1.map(s => {
            return <classes.board.selectItem key={s}>{s}</classes.board.selectItem>;
        })}
        </classes.board.selecteContent>
        <classes.board.subTitle>突破</classes.board.subTitle>
        <classes.board.selecteContent>
          {screener4_2.map(s => {
            return <classes.board.selectItem key={s}>{s}</classes.board.selectItem>;
        })}
        </classes.board.selecteContent>

        <classes.board.mainTitle>籌碼狀況</classes.board.mainTitle>
        <classes.board.subTitle>大戶比例</classes.board.subTitle>
        <classes.board.selecteContent>
          {screener6_1.map(s => {
            return <classes.board.selectItem key={s}>{s}</classes.board.selectItem>;
        })}
        </classes.board.selecteContent>
        <classes.board.subTitle>三大法人(買)</classes.board.subTitle>
        <classes.board.selecteContent>
          {screener6_2.map(s => {
            return <classes.board.selectItem key={s}>{s}</classes.board.selectItem>;
        })}
        </classes.board.selecteContent>
        <classes.board.subTitle>三大法人(賣)</classes.board.subTitle>
        <classes.board.selecteContent>
          {screener6_3.map(s => {
            return <classes.board.selectItem key={s}>{s}</classes.board.selectItem>;
        })}
        </classes.board.selecteContent>
      </classes.board.container>
      <classes.info.container>-</classes.info.container>
    </classes.container>);
});
const classes = {
    container: styled.div `
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    font-size: 14px;
  `,
    board: {
        container: styled.div `
      background-color: #252525;
    `,
        mainTitle: styled.div `
      ${fill_horizontal_cross_center};
      background-color: #484848;
      height: 32px;
    `,
        subTitle: styled.div `
      ${fill_horizontal_cross_center};
      height: 24px;
      color: #aaaaaa;
      margin-left: 4px;
      font-size: 13px;
    `,
        selecteContent: styled.div `
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      height: auto;
      gap: 4px;
      padding: 4px;
    `,
        selectItem: styled.div `
      ${fill_horizontal_all_center};
      height: 20px;
      min-width: 40px;
      width: auto;
      padding: 0 4px;
      border-radius: 4px;
      background-color: #686868;
      cursor: pointer;
      font-size: 13px;
    `,
    },
    info: {
        container: styled.div ``,
        chart: styled.div ``,
        list: styled.div ``,
    },
};
