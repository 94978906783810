import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { memo } from 'react';
import { useThemeStore } from '~/components/theme/useThemeStore';
import { fill, fill_horizontal_all_center, fill_vertical_all_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
export const Investment_Footer = memo(function Investment_Footer() {
    const theme = useThemeStore(t => t.theme);
    return (<classes.container css={css `
        background-color: ${theme === 'dark' ? '#1d1d1d' : '#1d1d1d'};
      `}>
      <div css={css `
          ${fill_vertical_cross_center};
          grid-column: 1 / 3;
          grid-row: 1 / 6;
          padding: 16px;
        `}>
        <div css={fill_horizontal_all_center}>
          <classes.favicon src='/futuresai/icon.png'/>
          <h2>期天資訊</h2>
        </div>
      </div>

      <div css={css `
          ${fill_vertical_all_center};
          align-items: start;
          grid-column: 3;
          grid-row: 2 / 4;
        `}>
        <Title1 />
      </div>
      <div css={css `
          ${fill_vertical_all_center};
          align-items: start;
          grid-column: 4;
          grid-row: 2 / 4;
        `}>
        <Title2 />
      </div>
      <div css={css `
          ${fill_vertical_all_center};
          align-items: start;
          grid-column: 5;
          grid-row: 2 / 4;
        `}>
        <Title3 />
      </div>
      <classes.copyright>© 2017-2023 期天資訊有限公司 版權所有</classes.copyright>
    </classes.container>);
});
const Title1 = memo(function Title1() {
    return (<div css={css `
        line-height: 14px;
      `}>
      <h4>合作項目</h4>
      <p>投顧合作</p>
      <p>券商合作</p>
      <p>建置合作</p>
    </div>);
});
const Title2 = memo(function Title2() {
    return (<div css={css `
        line-height: 14px;
      `}>
      <h4>關於我們</h4>
      <p>公司介紹</p>
      <p>團隊成員</p>
      <p>人才招募</p>
    </div>);
});
const Title3 = memo(function Title3() {
    return (<div css={css `
        line-height: 14px;
      `}>
      <h4>社群</h4>
      <p>Line官方帳號</p>
      <p>FaceBook粉絲專頁</p>
      <p>Youtube頻道</p>
      <p>微股力</p>
    </div>);
});
const classes = {
    container: styled.div `
    ${fill};
    display: grid;
    grid-template-columns: 14% 14% 24% 24% 24%;
    grid-template-rows: repeat(5, 20%);
    font-size: 14px;
  `,
    copyright: styled.div `
    ${fill_vertical_all_center};
    grid-column: 1 / 6;
    grid-row: 5;
    font-size: 13px;
  `,
    favicon: styled.img `
    width: 32px;
    height: 32px;
    grid-column: 1;
    grid-row: 1;
  `,
};
