import styled from '@emotion/styled';
import { memo, useEffect } from 'react';
import { useSnapshot } from 'valtio';
import { scrollbar2Css } from '~/css/scrollbarCss';
import { fill_vertical_cross_center } from '~/modules/AppLayout/FlexGridCss';
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2';
import { usePopularPick } from '~/modules/investment-consultant/stockPickTable/stockPickTable';
import StockFuturesSymbol from '~/pages/daddy960_stocknerve/component/StockFuturesSymbol';
import { staticStore } from '~/pages/heineken_template/_private/staticStore';
import { wu5868_strategiesDayTradeGroup } from '../wu5868_initStrategies';
import { store } from '~/pages/heineken_template/_private/store';
export const StockPopular = memo(function StockPopular() {
    const date = useSnapshot(staticStore).tradedDate.day0;
    const stock = usePopularPick({ date: date.format('YYYY-MM-DD'), sort: 'value', limit: 20 });
    const symbol = stock.resultSymbol ?? [];
    useEffect(() => {
        signalrStore2.addQuote(symbol);
        return () => {
            signalrStore2.removeQuote(symbol);
        };
    }, [JSON.stringify(symbol)]);
    return (<styleds.container>
      <styleds.headerContent>
        <StockFuturesSymbol.header />
      </styleds.headerContent>

      <styleds.lsitContent>
        {symbol?.map(s => {
            return (<StockFuturesSymbol.body handleClick={() => {
                    store.charting.setStrategy(wu5868_strategiesDayTradeGroup.main[0]);
                    store.charting.updateFromStrategyState();
                }} key={s} symbol={s}/>);
        })}
      </styleds.lsitContent>
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    ${fill_vertical_cross_center};
  `,
    headerContent: styled.div `
    width: 100%;
    height: 36px;
  `,
    lsitContent: styled.div `
    ${scrollbar2Css};
    height: calc(100% - 36px);
  `,
};
