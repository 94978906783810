import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
import dayAPI from '~/utils/dayAPI';
export const freeman_mid_cost = createIndicator({
    displayName: '大戶成本線',
    id: 'freeman-mid-cost',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            const itime = this.PineJS.Std.time(this._context);
            const hours = dayAPI(itime).hour();
            const minutes = dayAPI(itime).minute();
            const openTime = hours === 8 && minutes === 45;
            const high = this.ohlc.high;
            const low = this.ohlc.low;
            const dailyHigh = this._context.new_var();
            const dailyLow = this._context.new_var();
            if (openTime) {
                dailyHigh.set(high);
                dailyLow.set(low);
            }
            else if (high > dailyHigh.get(0)) {
                dailyHigh.set(high);
            }
            else if (low < dailyLow.get(0)) {
                dailyLow.set(low);
            }
            const dailyMid = (dailyHigh.get(0) + dailyLow.get(0)) / 2;
            return [dailyMid];
        },
    },
    metainfo: {
        plots: [
            {
                id: 'plot_0',
                type: 'line',
            },
        ],
        palettes: {
            palette_0: {
                colors: {
                    0: {
                        name: 'Color 0',
                    },
                    1: {
                        name: 'Color 1',
                    },
                },
            },
        },
        defaults: {
            styles: {
                plot_0: {
                    linestyle: 0,
                    linewidth: 2,
                    plottype: 0,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: '#c2c23e',
                },
            },
        },
        styles: {
            plot_0: {
                title: '大戶成本線',
                histogramBase: 0,
            },
        },
        is_price_study: !0,
        inputs: [],
        scriptIdPart: '',
    },
});
