import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
/**
 * High: "trust_diff", low: "dealer_diff", open: "foreigner_diff", close: "上面加總", volume:
 * dealer_hedge_diff
 */
export const edwin_trust = createIndicator({
    id: 'edwin-投信買賣超',
    displayName: '投信買賣超',
    constructorScope: {
        init(context, inputCallback) {
            const ticker = this.PineJS.Std.ticker(this._context)
                .toString()
                .replace(/[^\w-]/g, '');
            const symbol = ticker + '#big3_net_stock';
            this._context.new_sym(symbol, this.PineJS.Std.period(this._context));
        },
        main(context, inputCallback) {
            this._context.select_sym(1);
            const data = this.PineJS.Std.high(this._context);
            const data_array = this._context.new_var(data);
            const buy = data > 0 ? data : NaN;
            const sell = data < 0 ? data : NaN;
            const date = this.PineJS.Std.time(this._context);
            const date_array = this._context.new_var(date);
            //要找尋的天數
            const days = this._input(0);
            data_array.get(120);
            date_array.get(120);
            //最近N天的累積買超總合
            const setAccData = this.PineJS.Std.sum(data_array, days, this._context);
            return [buy, sell, setAccData];
        },
    },
    metainfo: {
        _metainfoVersion: 40,
        scriptIdPart: '',
        is_hidden_study: false,
        is_price_study: false,
        isCustomIndicator: true,
        plots: [
            { id: 'plot_0', type: 'line' },
            { id: 'plot_1', type: 'line' },
            { id: 'plot_2', type: 'line' },
        ],
        defaults: {
            styles: {
                plot_0: {
                    linestyle: 0,
                    visible: true,
                    linewidth: 2,
                    plottype: 5,
                    trackPrice: false,
                    transparency: 15,
                    color: '#eb4849',
                },
                plot_1: {
                    linestyle: 0,
                    visible: true,
                    linewidth: 2,
                    plottype: 5,
                    trackPrice: false,
                    transparency: 15,
                    color: '#118b7d',
                },
                plot_2: {
                    linestyle: 0,
                    visible: true,
                    linewidth: 1,
                    plottype: 7,
                    trackPrice: false,
                    transparency: 15,
                    color: '#ffee00',
                },
            },
            precision: 0,
            inputs: { in_0: 20 },
        },
        styles: {
            plot_0: {
                title: '買超',
                histogramBase: 0,
            },
            plot_1: {
                title: '賣超',
                histogramBase: 0,
            },
            plot_2: {
                title: '累積買超',
                histogramBase: 0,
            },
        },
        inputs: [{ id: 'in_0', name: '累積買超(天)', defval: 20, type: 'integer', min: 2, max: 120 }],
    },
});
