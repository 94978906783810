import { css } from '@emotion/react';
import { memo } from 'react';
import { CartesianGrid, Line, LineChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis, } from 'recharts';
import { fill_horizontal_all_center } from '~/modules/AppLayout/FlexGridCss';
import { useOptionsGreeksResource } from '~/modules/options/api/useOptionsGreeksResource';
import { useSnapshot } from 'valtio';
import { optionAnalyzeStore } from './optionAnalyze/optionAnalyzeStore';
import { filter } from 'lodash';
import { filterSocket } from '~/modules/options/utils/filterSocketUtil';
import { useParseOptionsContractMonthString } from '~/modules/options/utils/useParseOptionsContractMonthString';
import { useThemeStore } from '~/components/theme/useThemeStore';
export const OptionIVChart = memo(function OptionIVChart() {
    //使用 useOptionsGreeksResource
    //將call與put資料分開
    //畫出兩條個別的曲線
    const state = useSnapshot(optionAnalyzeStore);
    const contract = state.currentContract;
    const getGreeks = useOptionsGreeksResource({
        contract,
    }).res.data ?? [
        {
            symbol: '0',
            delta: 0,
            iv: 0,
        },
    ];
    const contractObject = useParseOptionsContractMonthString(contract);
    /** 將波動率報價過濾出`買權` */
    const ivCallSource = filter(getGreeks, datum => filterSocket(datum, 'CALL', contractObject));
    /** 將波動率報價過濾出`賣權` */
    const ivPutSource = filter(getGreeks, datum => filterSocket(datum, 'PUT', contractObject));
    const mergedData = ivPutSource.map(putItem => {
        const putSymbolWithoutTX = putItem.symbol.slice(3, -2);
        const correspondingCallItem = ivCallSource.find(callItem => callItem.symbol.slice(3, -2) === putSymbolWithoutTX);
        return {
            symbol: putSymbolWithoutTX,
            putDelta: putItem.delta,
            putIV: putItem.iv,
            callDelta: correspondingCallItem ? correspondingCallItem.delta : 0,
            callIV: correspondingCallItem ? correspondingCallItem.iv : 0,
        };
    });
    const atTheMoneyPrice = state.atTheMoneyPrice;
    const index = mergedData.findIndex(item => item.symbol.includes(atTheMoneyPrice?.toFixed(0)));
    const start = Math.max(0, index - 4);
    const end = Math.min(mergedData.length, index + 5);
    const sliceMergedData = mergedData.slice(start, end);
    const maxPutIV = Math.max(...(sliceMergedData?.map(s => s.callIV) ?? [0]));
    const maxCallIV = Math.max(...(sliceMergedData?.map(s => s.putIV) ?? [0]));
    const minPutIV = Math.min(...(sliceMergedData?.map(s => s.callIV) ?? [0]));
    const minCallIV = Math.min(...(sliceMergedData?.map(s => s.putIV) ?? [0]));
    const maxIV = Math.max(maxPutIV, maxCallIV) * 1.05;
    const minIV = Math.min(minPutIV, minCallIV) * 0.995;
    const midIV = (maxIV + minIV) / 2;
    return (<div css={css `
        ${fill_horizontal_all_center};
        padding: 4px;
        border-radius: 10px;
        background-color: #1a1a1a;
      `}>
      <ResponsiveContainer width='100%' height='100%'>
        <LineChart width={500} height={300} data={sliceMergedData} margin={{
            top: 14,
            right: 30,
            left: 20,
            bottom: 5,
        }}>
          <CartesianGrid strokeDasharray='2 2' stroke={classesFill.gridFill}/>
          <XAxis dataKey='symbol' stroke={classesFill.storkFill} tick={{ fontSize: 14 }} tickMargin={8}/>
          <YAxis stroke={classesFill.storkFill} tick={{ fontSize: 14 }} tickMargin={8} type={'number'} domain={[maxIV, minIV]} ticks={[maxIV, (maxIV + midIV) / 2, midIV, (minIV + midIV) / 2, minIV]} tickFormatter={str => Number(str * 100).toFixed(1) + '%'}/>
          <ReferenceLine x={atTheMoneyPrice} stroke={classesFill.atmPriceLineFill} strokeWidth={2} strokeDasharray='1 1'/>
          <Line type='monotone' dataKey='callIV' stroke='#aa4444' activeDot={{ r: 8 }} strokeWidth={2} isAnimationActive={false}/>
          <Line type='monotone' dataKey='putIV' stroke='#44aa44' strokeWidth={2} isAnimationActive={false}/>
          <Tooltip content={<CustomizedTooltip />}/>
        </LineChart>
      </ResponsiveContainer>
    </div>);
});
const classesFill = {
    storkFill: '#cccccc',
    lineFill: '#ddcc00',
    gridFill: '#555555',
    referenceLineFill: '#aaaaaa',
    referenceDotFill: '#ffffff',
    atmPriceLineFill: '#425fb1',
};
const CustomizedTooltip = ({ payload }) => {
    const theme = useThemeStore(t => t.theme);
    const fill = theme === 'dark' ? '#353535aa' : '#ddddddaa';
    if (!payload || (payload && payload.length < 1))
        return null;
    const datum = payload[0].payload;
    return (<div css={css `
        background-color: ${fill};
        border-radius: 7px;
        padding: 2px 8px;
        & > p {
          padding: 0px;
          line-height: 14px;
        }
      `}>
      <p>標的物價格: {datum.symbol}</p>
      <p css={css `
          color: #aa4444;
        `}>
        CALL IV: {(datum.callIV * 100)?.toFixed(2)}%
      </p>
      <p css={css `
          color: #44aa44;
        `}>
        PUT IV: {(datum.putIV * 100)?.toFixed(2)}%
      </p>
    </div>);
};
